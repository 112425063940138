import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Label, Select } from "../../../../../../Inputs";
import { Text, Textarea } from "../../../../../../Inputs/react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { AuthContext } from "../../../../../../Authorization/AuthContext";
import { FlexRow } from "../../../../../../Common/__styles__/Layout";
import { range } from "lodash";
import Collapsible from "react-collapsible";
import { Button } from "../../../../../../Common/Button";
import { formatCollection, pluralize } from "common/utils/strings";
import { FieldSettingConfig } from "./shared";
import { DOCUMENT_TYPE_NAME, MIME_TYPE } from "common/constants";
import { MAX_SIZE_PER_MIME_TYPE_IN_MB } from "common/utils/documentUploads";

const getDefaultAdvancedSettings = ({
  allowedMimeTypes,
  documentTypeName,
  maxItems,
  minItems,
}: {
  allowedMimeTypes?: Array<string>;
  documentTypeName?: string;
  maxItems: number;
  minItems: Maybe<number>;
}) => {
  if (!allowedMimeTypes || !documentTypeName) {
    return {
      fileTypeDescription: "",
      fileQuantityDescription: "",
      fileSizeDescription: "",
      documentTypeAbbreviation: "",
      emptyFilesMessage: "",
    };
  }

  // Mimetypes are in the format `image/jpeg` so we want to extract the second part to display
  const formattedMimeTypes = formatCollection(
    allowedMimeTypes.map(mimeType => mimeType.split("/")[1]!.toUpperCase()),
    "or"
  );
  const emptyFilesMessage = minItems
    ? "You must upload at least one file."
    : "";

  const maximumSizeInMB = allowedMimeTypes.reduce((acc, mimeType) => {
    const size = MAX_SIZE_PER_MIME_TYPE_IN_MB[mimeType as MIME_TYPE];
    return Math.max(acc, size);
  }, 0);

  switch (documentTypeName) {
    case DOCUMENT_TYPE_NAME.PROPERTY_IMAGE:
      return {
        fileTypeDescription: `We only allow ${formattedMimeTypes} uploads at this time. If you have an image with another file type, please convert it to ${formattedMimeTypes} before uploading.`,
        fileQuantityDescription: `You can upload as many as ${maxItems} ${pluralize(
          { count: maxItems, options: { singular: "image", plural: "images" } }
        )}. If you have additional property images, please start a new inspection or upload to the property.`,
        fileSizeDescription: `The size limit for each individual file is ${maximumSizeInMB} MB. Please reduce the file size of your images before uploading if they exceed this limit.`,
        documentTypeAbbreviation: "image",
        documentTypeName,
        emptyFilesMessage,
      };
    case DOCUMENT_TYPE_NAME.ELEVATION_CERTIFICATE:
      return {
        fileTypeDescription:
          "We only allow PDF uploads at this time. If you have another type of file, please convert it to PDF before uploading.",
        fileQuantityDescription: `You should only upload ${
          maxItems === 1 ? "a single" : maxItems
        } ${pluralize({
          count: maxItems,
          options: {
            singular: "Elevation Certificate",
            plural: "Elevation Certificates",
          },
        })} per property. If you have other ECs, please start a new submission.`,
        fileSizeDescription: `The size limit for PDFs is ${maximumSizeInMB} MB. Please reduce the file size of your PDF before posting if it exceeds this limit.`,
        documentTypeAbbreviation: "EC",
        documentTypeName,
        emptyFilesMessage,
      };

    default:
      return {
        fileTypeDescription: `We only allow ${formattedMimeTypes} uploads at this time. If you have a file of another type, please convert it to ${formattedMimeTypes} before uploading.`,
        fileQuantityDescription: `You can upload as many as ${maxItems} ${pluralize(
          { count: maxItems, options: { singular: "file", plural: "files" } }
        )}.`,
        fileSizeDescription: `The size limit for each individual file is ${maximumSizeInMB} MB. Please reduce the file size of your files before uploading if they exceed this limit.`,
        documentTypeAbbreviation: "file",
        documentTypeName,
        emptyFilesMessage,
      };
  }
};

const getMinMaxOptions = ({
  limit,
  isMin,
}: {
  limit: number;
  isMin: boolean;
}) => {
  const min = isMin ? 1 : limit;
  const max = isMin ? limit : 25;

  // range is non-inclusive so we need to add 1 to the max
  return range(min, max + 1).map(value => ({
    label: value.toString(),
    value: value,
  }));
};

const DocumentUploadFieldSettings = ({
  fieldPath,
  isModuleInput,
}: Exclude<SelectedSubmissionsBuilderField, null> & {
  isModuleInput: boolean;
}) => {
  const { account } = useContext(AuthContext);
  const { getValues, register, setValue } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  // We cannot pass the result of `getValues` to `useState` directly
  // because it breaks some type inference downstream.
  const formValues = getValues([
    `${fieldPath}.accountDocumentType`,
    `${fieldPath}.minItems`,
    `${fieldPath}.maxItems`,
    `${fieldPath}.required`,
  ]);

  const [
    [
      initialAccountDocumentType,
      initialMinItems,
      initialMaxItems,
      initialAreFilesRequired,
    ],
  ] = useState(formValues);

  const [accountDocumentTypeId, setAccountDocumentTypeId] = useState<
    Maybe<string> | undefined
  >(initialAccountDocumentType);

  const [minItems, setMinItems] =
    useState<Maybe<number | undefined>>(initialMinItems);
  const [maxItems, setMaxItems] =
    useState<Maybe<number | undefined>>(initialMaxItems);
  const [show, toggleShow] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const accountDocumentTypeOptions =
    account?.accountDocumentTypes.map(accountDocumentType => ({
      label: accountDocumentType.name,
      value: accountDocumentType.id,
    })) ?? [];

  const [areFilesRequired, setAreFilesRequired] = useState(
    initialAreFilesRequired
  );

  useEffect(() => {
    if (areFilesRequired) {
      setMinItems(initialMinItems ?? 1);
    } else {
      setMinItems(null);
    }
  }, [areFilesRequired]);

  useEffect(() => {
    // This is to respect existing values
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    const castMaxItems = maxItems ?? 15;
    const castMinItems = minItems ?? 0;
    const accountDocumentType = account!.accountDocumentTypes.find(
      accountDocumentType => accountDocumentType.id === accountDocumentTypeId
    );
    const defaults = getDefaultAdvancedSettings({
      allowedMimeTypes: accountDocumentType?.allowedMimeTypes,
      documentTypeName:
        accountDocumentType?.documentType?.name ?? accountDocumentType?.name,
      maxItems: castMaxItems,
      minItems: castMinItems,
    });

    Object.entries(defaults).forEach(([key, value]) => {
      setValue(`${fieldPath}.${key as keyof typeof defaults}`, value, {
        shouldValidate: true,
      });
    });
  }, [accountDocumentTypeId, maxItems, minItems]);

  useEffect(() => {
    if (minItems && maxItems && minItems > maxItems) {
      setMinItems(maxItems);
    }
  }, [minItems, maxItems]);

  useEffect(() => {
    setValue(`${fieldPath}.minItems`, minItems ?? undefined, {
      shouldValidate: true,
    });
  }, [minItems]);

  useEffect(() => {
    setValue(`${fieldPath}.maxItems`, maxItems ?? undefined, {
      shouldValidate: true,
    });
  }, [maxItems]);

  const trigger = (
    <Button
      styleVariant={"ghost"}
      rightIconName={show ? "chevron-up" : "chevron-down"}
      style={{ marginTop: "8px", marginBottom: "16px" }}
    >
      Advanced settings
    </Button>
  );

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig
        fieldPath={fieldPath}
        isModuleInput={isModuleInput}
        onRequiredChange={evt => setAreFilesRequired(evt.target.checked)}
      />
      <div>
        <Label text={"Document type"} required />
        <Select
          name={`${fieldPath}.accountDocumentType`}
          options={accountDocumentTypeOptions}
          value={accountDocumentTypeId}
          onChange={value => {
            setValue(`${fieldPath}.accountDocumentType`, value ?? undefined, {
              shouldValidate: true,
            });
            setAccountDocumentTypeId(value);
          }}
        />
      </div>
      <FlexRow style={{ gap: 16 }}>
        <div style={{ flexBasis: "50%" }}>
          <Label
            text={"File upload minimum"}
            required={areFilesRequired}
            htmlFor={`${fieldPath}.minItems`}
          />
          <Select
            name={`${fieldPath}.minItems`}
            options={getMinMaxOptions({ isMin: true, limit: maxItems ?? 15 })}
            value={minItems}
            onChange={value => {
              setMinItems(value);
            }}
            disabled={!areFilesRequired}
          />
        </div>
        <div style={{ flexBasis: "50%" }}>
          <Label text={"File upload maximum"} required />
          <Select
            name={`${fieldPath}.maxItems`}
            options={getMinMaxOptions({ isMin: false, limit: minItems ?? 0 })}
            value={maxItems}
            onChange={value => {
              setMaxItems(value);
            }}
          />
        </div>
      </FlexRow>
      {!!accountDocumentTypeId && (
        <Collapsible
          trigger={trigger}
          transitionTime={100}
          onOpen={() => {
            toggleShow(true);
          }}
          onClose={() => {
            toggleShow(false);
          }}
        >
          <WidgetSettingsContainer>
            <div>
              <Label text={"File type description"} required />
              <Textarea {...register(`${fieldPath}.fileTypeDescription`)} />
            </div>
            <div>
              <Label text={"File quantity description"} required />
              <Textarea {...register(`${fieldPath}.fileQuantityDescription`)} />
            </div>
            <div>
              <Label text={"File size description"} required />
              <Textarea {...register(`${fieldPath}.fileSizeDescription`)} />
            </div>
            <div>
              <Label text={"Document type abbreviation"} required />
              <Text {...register(`${fieldPath}.documentTypeAbbreviation`)} />
            </div>
            <div>
              <Label text={"Empty files message"} />
              <Text {...register(`${fieldPath}.emptyFilesMessage`)} />
            </div>
          </WidgetSettingsContainer>
        </Collapsible>
      )}
    </WidgetSettingsContainer>
  );
};

DocumentUploadFieldSettings.displayName = "DocumentUploadFieldSettings";

export default DocumentUploadFieldSettings;
