import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Select, Text } from "../../../../../../Inputs/react-hook-form";
import { Button } from "../../../../../../Common/Button";
import { SubmissionTagDisplay } from "../../../../../../../generated/graphql";
import { useModal } from "react-modal-hook";
import Modal from "../../../../../../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../../../Common/__styles__/Modal";
import { FlexColumn } from "../../../../../../Common/__styles__/Layout";

export type Option = {
  label: string;
  value: string;
  display?: SubmissionTagDisplay;
};

export const useCreateEditDropdownOptionModal = () => {
  const [showProps, setShowProps] = useState<
    Omit<CreateEditDropdownOptionModalParams, "onCancel">
  >({
    onSave: () => {},
    useAsTag: false,
    valueEditingDisabled: false,
  });
  const [show, hide] = useModal(
    () => (
      <Modal onRequestClose={hide}>
        <CreateEditDropdownOptionModal onCancel={hide} {...showProps} />
      </Modal>
    ),
    [showProps]
  );

  const showWithProps = ({
    onDelete,
    onSave,
    ...params
  }: Omit<CreateEditDropdownOptionModalParams, "onCancel">) => {
    setShowProps({
      onDelete: onDelete
        ? () => {
            onDelete();
            hide();
          }
        : undefined,
      onSave: props => {
        onSave(props);
        hide();
      },
      ...params,
    });
    show();
  };

  return [showWithProps, hide] as const;
};

type CreateEditDropdownOptionModalParams = {
  existingOption?: Option;
  onCancel: () => void;
  onDelete?: () => void;
  onSave: (props: Option) => void;
  useAsTag: boolean;
  valueEditingDisabled: boolean;
};

export const CreateEditDropdownOptionModal = ({
  existingOption,
  onCancel,
  onDelete,
  onSave,
  useAsTag,
  valueEditingDisabled,
}: CreateEditDropdownOptionModalParams) => {
  let defaultValues: Partial<Option>;
  if (existingOption) {
    defaultValues = existingOption;
  } else if (useAsTag) {
    defaultValues = {
      display: SubmissionTagDisplay.NEUTRAL,
    };
  } else {
    defaultValues = {};
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<Option>({
    defaultValues,
  });

  const headerVerb = existingOption ? "Edit" : "Add";

  const labelValue = watch("label");

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    if (!valueEditingDisabled) {
      setValue("value", labelValue);
    }
  }, [labelValue]);

  return (
    <Container overflows>
      <HeaderSection>
        <h1>{headerVerb} dropdown option</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          <FlexColumn style={{ gap: "16px" }}>
            <div>
              <Label text={"Label"} htmlFor="label" required />
              <Text
                {...register("label", { required: "Label is required" })}
                error={errors.label?.message}
              />
            </div>
            <div>
              <Label text={"Value"} htmlFor="value" required />
              <Text
                {...register("value", { required: "Value is required" })}
                disabled={!labelValue || valueEditingDisabled}
                error={errors.value?.message}
              />
            </div>
            {useAsTag && (
              <div>
                <Select
                  name="display"
                  label="Tag color"
                  control={control}
                  error={errors.display?.message}
                  options={[
                    {
                      label: "Default - blue",
                      value: SubmissionTagDisplay.DEFAULT,
                    },
                    {
                      label: "Neutral - grey",
                      value: SubmissionTagDisplay.NEUTRAL,
                    },
                    {
                      label: "Warning - yellow",
                      value: SubmissionTagDisplay.WARNING,
                    },
                    {
                      label: "Critical - red",
                      value: SubmissionTagDisplay.CRITICAL,
                    },
                  ]}
                />
              </div>
            )}
          </FlexColumn>
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          {!!existingOption && onDelete && (
            <Button
              size="medium"
              styleVariant="buttonLinkAlert"
              onClick={onDelete}
            >
              Delete
            </Button>
          )}

          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            type="button"
            onClick={handleSubmit(onSave)}
          >
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
