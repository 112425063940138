import React, { useEffect, useState } from "react";
import { isNil } from "lodash";

import { StreetViewLink } from "./__styles__";

const API_URL_BASE = "/api/streetViewImage";
const STREETVIEW_URL_BASE = "https://www.google.com/maps?q&layer=c&cbll=";

interface Props {
  size?: string;
  latitude?: Maybe<number>;
  longitude?: Maybe<number>;
  onError: (err: any) => void;
  httpClient?: typeof fetch;
}

export const useGoogleStreetViewImage = ({
  size = "600x400",
  latitude,
  longitude,
  onError,
  httpClient = fetch,
}: Props) => {
  const [googleStreetViewImage, setGoogleStreetViewImage] =
    useState<Maybe<string>>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const abortReason = "Component unmounted";
    if (latitude && longitude) {
      setLoading(true);
      const params = new URLSearchParams({
        size,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      });

      httpClient(`${API_URL_BASE}/metadata?${params.toString()}`, {
        method: "GET",
        signal,
      })
        .then(async response => {
          if (!response.ok) {
            return Promise.reject(
              `Received a ${response.status} when fetching street view image`
            );
          }

          const json = await response.json();
          if (json.status === "OK") {
            setGoogleStreetViewImage(`${API_URL_BASE}?${params.toString()}`);
          }
          setLoading(false);
          return;
        })
        .catch((err: unknown) => {
          if (signal.aborted && signal.reason === abortReason) {
            return;
          }
          setLoading(false);
          onError(err);
        });
    }
    return () => {
      controller.abort(abortReason);
    };
  }, [latitude, longitude]);

  return { imageURL: googleStreetViewImage, loading };
};

export const ViewOnStreetViewButton = ({
  latitude,
  longitude,
}: Pick<Props, "latitude" | "longitude">) => {
  const streetViewLink =
    !isNil(latitude) && !isNil(longitude)
      ? `${STREETVIEW_URL_BASE}${latitude},${longitude}`
      : null;

  return streetViewLink ? (
    <StreetViewLink
      href={streetViewLink}
      target="_blank"
      data-testid="streetview-link"
    >
      View on Google Streetview
    </StreetViewLink>
  ) : null;
};
