import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router";
import { MapRef } from "react-map-gl";

import AddressPanel from "../AddressPanel";
import { track } from "../../utils/tracking";
import FullPageMapContainer from "./FullPageMap";
import AccountActivityFeed from "./AccountActivityFeed";

import { ActivityFeed, Export, Info } from "./__styles__/Map";
import { isSmall } from "../../utils/size";
import { ParcelLayerConfig } from "./LayeredMap/parcelLayer";
import { PropertyLayerConfig } from "./LayeredMap/propertyLayer";
import {
  LegendControl,
  MapTool,
  MapToolLabel,
  Container,
} from "./__styles__/FullPageMap";
import { Icon as LucideIcon } from "../Common/Icons/LucideIcons";
import { Viewport } from "./utils/viewportHook";
import { ADDRESS_PANEL_TAB_NAME, buildLink } from "common/routing";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../Authorization/AuthContext";
import { SearchResultProps } from "../Search";
import { InternalMapContext } from "./InternalMapContextProvider";
import { LayerContext } from "./layers";
import ExportDataButton from "../Exports/ExportDataButton";
import { isNil } from "lodash";
import { Property } from "./LayeredMap/types";
import { outsideOfBoundary } from "common-client/utils/geospatial";
import { SavedViewsLayerConfig } from "./LayeredMap/savedViewsLayer";
import { useUserDeviceLocations } from "./UserDeviceLocation/useUserDeviceLocations";

interface Selected {
  address: string;
  longitude: number;
  latitude: number;
  propertyId?: string;
  parcelId?: string;
  parcelNumber?: string;
  hasPropertyIds?: string;
}

const isValidSelected = (params: any): params is Selected => {
  if (params?.lat && params?.lng) {
    params.latitude = parseFloat(params.lat);
    params.longitude = parseFloat(params.lng);
    return true;
  }
  return false;
};

const MapControls = ({ viewport }: { viewport: Viewport }) => {
  const history = useHistory();
  const { authorized, account } = useContext(AuthContext);

  const canCreateProperty = authorized({
    resource: RESOURCE_NAME.PROPERTY,
    permission: "create",
  });

  const createProperty = ({ viewport }: { viewport: Viewport }) => {
    const pathname = buildLink("createProperty");

    const queryParameters = {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: viewport.zoom,
    };

    history.push({
      pathname,
      search: `?${queryString.stringify(queryParameters)}`,
    });
  };

  if (
    !canCreateProperty ||
    outsideOfBoundary({ point: viewport, boundary: account!.bounds })
  ) {
    return null;
  }

  return (
    <MapTool>
      <MapToolLabel>Add new property</MapToolLabel>
      <LegendControl
        styleVariant="outlineLight"
        onClick={() => createProperty({ viewport })}
      >
        <LucideIcon iconName="map-pin" color="contentPrimary" size={16} />
      </LegendControl>
    </MapTool>
  );
};

export const Map = () => {
  const history = useHistory();
  const [selected, setSelected] = useState<Maybe<Selected>>(null);
  const { resetSearchCategory } = useContext(InternalMapContext);
  const {
    measureToolDispatch,
    measureToolState,
    visibleRaster,
    visibleSavedViews: getVisibleSavedViews,
    toggleLayer,
    approximateBfeToolDispatch,
    approximateBfeToolState,
  } = useContext(LayerContext);
  const mapRef = useRef<Maybe<MapRef>>(null);

  const userDeviceLocations = useUserDeviceLocations();

  // we don't want the address panel to re-render every time *this* re-renders,
  // so we  memoize this callback so that creating it happens only once
  const onAddressPanelClose = useMemo(
    () => () => {
      if (
        measureToolState.measureToolMode === "raster" &&
        measureToolState.dataMode === "store"
      ) {
        measureToolDispatch({
          type: "setMeasureMode",
          data: {
            measureToolMode: "off",
          },
        });

        toggleLayer({
          group: "rasters",
          id: visibleRaster()?.id,
          isVisible: false,
        });
      }
      if (approximateBfeToolState?.mode !== "off") {
        approximateBfeToolDispatch?.({
          type: "setMode",
          data: {
            mode: "off",
          },
        });
      }

      history.replace({ pathname: buildLink("map") });
    },
    [
      history,
      measureToolState.measureToolMode,
      measureToolState.dataMode,
      approximateBfeToolState?.mode,
    ]
  );

  const updateURL = (
    queryParameters: Record<string, string | number | null>
  ) => {
    history.replace({
      pathname: buildLink("map"),
      search: `?${queryString.stringify(queryParameters)}`,
    });
  };

  const onParcelClick: ParcelLayerConfig["onClick"] = parcels => {
    const parcel = parcels[0]!;

    const tab =
      queryString.parse(location.search).tab || ADDRESS_PANEL_TAB_NAME.OVERVIEW;
    const { fullAddress, id, parcelNumber, propertyIds } = parcel;
    const { longitude, latitude } = parcel.point;
    const hasPropertyIds = propertyIds?.length ? "true" : "false";

    const queryParameters = {
      address: fullAddress ? fullAddress : null,
      lng: longitude,
      lat: latitude,
      tab: tab as string,
      parcelId: id,
      parcelNumber,
      hasPropertyIds,
    };

    navigateToProperty(queryParameters);
  };

  const getAddressQueryParam = (property: Property) => {
    const { address, city, state, zipcode } = property;

    if (isNil(address) || isNil(city) || isNil(state) || isNil(zipcode)) {
      return undefined;
    }

    return `${address}, ${city}, ${state} ${zipcode}`;
  };

  const onFeatureClick = ({
    property,
    defaultTab,
  }: {
    property: Property;
    defaultTab: ADDRESS_PANEL_TAB_NAME;
  }) => {
    const address = getAddressQueryParam(property);

    const { point, id, parcel } = property;
    const { longitude, latitude } = point;

    const queryParameters: Record<string, string | number> = {
      lng: longitude,
      lat: latitude,
      propertyId: id,
      tab: defaultTab,
    };

    if (address) {
      queryParameters.address = address;
    }

    if (parcel) {
      queryParameters.parcelId = parcel.id;
      queryParameters.parcelNumber = parcel.parcelNumber;
    }

    navigateToProperty(queryParameters);
  };

  const onPropertyClick: PropertyLayerConfig["onClick"] = property => {
    const defaultTab =
      queryString.parse(location.search).tab || ADDRESS_PANEL_TAB_NAME.OVERVIEW;

    onFeatureClick({
      property,
      defaultTab: defaultTab as ADDRESS_PANEL_TAB_NAME,
    });
  };

  const onSavedViewClick: SavedViewsLayerConfig["onClick"] = ({
    ...property
  }) => {
    onFeatureClick({ property, defaultTab: ADDRESS_PANEL_TAB_NAME.OVERVIEW });
  };

  const navigateToProperty = (
    queryParameters: Record<string, Maybe<string | number>>
  ) => {
    track("Property clicked");
    resetSearchCategory();
    updateURL(queryParameters);
  };

  const onSearchResult = (data: SearchResultProps) => {
    const { longitude, latitude } = data.point;
    const address = data.address;
    const propertyId = data.propertyId;
    const parcelId = data.geocacheParcelId;

    track("Address Searched");

    const queryParameters = {
      address,
      lng: longitude,
      lat: latitude,
      propertyId: propertyId || null,
      parcelId: parcelId || null,
      tab: ADDRESS_PANEL_TAB_NAME.OVERVIEW,
    };

    updateURL(queryParameters);
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (isValidSelected(params)) {
      const {
        address,
        longitude,
        latitude,
        propertyId,
        parcelId,
        parcelNumber,
        hasPropertyIds,
      } = params;

      setSelected({
        address,
        longitude,
        latitude,
        propertyId,
        parcelId,
        parcelNumber,
        hasPropertyIds,
      });
    } else {
      setSelected(null);
    }
  }, [location.search]);

  const ADDRESS_PANEL_WIDTH = 548;

  const viewportDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    let newWidth;
    let newHeight;

    if (isSmall()) {
      // On mobile, we center the area width-wise and place it near the top
      // heigth-wise so that it sits above the AddressPanel
      newHeight = height - height / 5;
      newWidth = width / 2;
    } else {
      // On desktop, we center it width-wise taking into account the width of the
      // AddressPanel and center it heighth-wise
      newHeight = height / 2;
      newWidth = (width - ADDRESS_PANEL_WIDTH) / 2;
    }

    return { width: newWidth, height: newHeight };
  };

  const markerConfig = { value: selected?.propertyId ? selected : null };

  const parcelConfig = {
    interactive: { hover: true, click: true },
    onClick: onParcelClick,
    value: selected ? { id: selected.parcelId, point: { ...selected } } : null,
  };

  const propertyConfig = {
    filterHidden: false,
    geocacheParcelId: selected?.parcelId,
    interactive: { hover: true, click: true },
    onClick: onPropertyClick,
  };

  const visibleSavedViews = getVisibleSavedViews();

  const savedViewsConfig = {
    onClick: onSavedViewClick,
    value: visibleSavedViews,
    interactive: {
      hover: !!visibleSavedViews?.length,
      click: !!visibleSavedViews?.length,
    },
  };

  return (
    <Container>
      <FullPageMapContainer
        markerConfig={markerConfig}
        parcelConfig={parcelConfig}
        propertyConfig={propertyConfig}
        savedViewsConfig={savedViewsConfig}
        deviceLocationsConfig={userDeviceLocations}
        onSearchResult={onSearchResult}
        viewportDimensions={viewportDimensions}
        MapControls={MapControls}
        ref={mapRef}
      >
        <Export>
          <ExportDataButton />
        </Export>
        {!selected && (
          <ActivityFeed>
            <AccountActivityFeed />
          </ActivityFeed>
        )}
      </FullPageMapContainer>
      {!!selected && (
        <Info>
          <AddressPanel
            address={selected.address}
            longitude={selected.longitude}
            latitude={selected.latitude}
            propertyId={selected.propertyId}
            parcelId={selected.parcelId}
            parcelNumber={selected.parcelNumber}
            hasPropertyIds={selected.hasPropertyIds === "true"}
            onClose={onAddressPanelClose}
          />
        </Info>
      )}
    </Container>
  );
};
