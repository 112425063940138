import React, { useMemo } from "react";
import { LayerConfig, LayerHookBuilder, LayerHook } from "./types";
import DeviceLocationsLayer from "../layers/userDeviceLocations";
import { UserDeviceLocation } from "../../../generated/graphql";

export type DeviceLocationsLayerConfig = LayerConfig<
  Array<
    Pick<UserDeviceLocation, "location" | "id" | "trackedAt"> & {
      userName: string;
      deviceDescription?: Maybe<string>;
    }
  >
>;

const deviceLocationsHook: LayerHookBuilder<DeviceLocationsLayerConfig> = ({
  config,
}) => {
  return useMemo<LayerHook>(() => {
    return {
      // -2 so that the markers show above the PIP's marker
      zIndex: -2,
      render: () => {
        return (
          <DeviceLocationsLayer
            key="device-locations"
            locations={config.value}
          />
        );
      },
      canHandleHover: () => !!config.value?.length,
    };
  }, [config]);
};

export default deviceLocationsHook;
