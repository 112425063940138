import React, { useRef } from "react";
import { NetworkStatus, WatchQueryFetchPolicy } from "@apollo/client";
import { uniqBy } from "lodash";
import Collapsible from "react-collapsible";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { useGetAccountActivityQuery } from "../../generated/graphql";
import Feed, { Activity } from "../Feed";
import { Button } from "../Common/Button";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import {
  AccountActivityFeedContainer,
  EmptyFeedContainer,
  EmptyIcon,
  FeedContainer,
  MinimizedFeedContainer,
  Toggle,
  ViewMoreContainer,
} from "./__styles__/AccountActivityFeed";
import { useOutsideAlerter } from "../../utils/outsideAlerter";

const EmptyFeed = (
  <EmptyFeedContainer>
    <EmptyIcon>
      <Icon icon={Icons.ACTIVITY} color={ICON_COLORS.GREY} />
    </EmptyIcon>
    <span>You have no recent activity</span>
  </EmptyFeedContainer>
);

const LoadingFeed = (
  <EmptyFeedContainer data-testid="loading-indicator">
    <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
  </EmptyFeedContainer>
);

const AccountActivityFeed = ({
  fetchPolicy = "cache-and-network",
  pageSize = 5,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
  pageSize?: number;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [activities, setActivities] = React.useState<Array<Activity>>([]);
  const [deletedActivityId, setDeletedActivityId] = React.useState("");

  const { user, admin } = React.useContext(AuthContext);

  const wrapperRef = useRef(null as null | HTMLDivElement);
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setIsOpen(false),
  });

  const { data, networkStatus, error, previousData, refetch } =
    useGetAccountActivityQuery({
      variables: { page, pageSize },
      fetchPolicy,
      onCompleted: data => {
        setActivities(
          uniqBy(
            [
              ...activities.filter(
                activity => activity.id !== deletedActivityId
              ),
              ...data.accountActivities.data.map(activity => ({
                ...activity,
              })),
            ],
            "id"
          )
        );
      },
    });

  if (((!previousData && !data) || error) && isOpen) {
    return LoadingFeed;
  }

  const onUpdate = async (deletedId?: string) => {
    setDeletedActivityId(deletedId ?? "");
    await refetch();
  };

  const MinimizedFeed = (
    <MinimizedFeedContainer
      data-testid="minimized-feed"
      onClick={() => setIsOpen(!isOpen)}
    >
      <h4>Activity Feed</h4>
      <Toggle rotate={isOpen}>
        <Icon icon={Icons.CARET_DOWN_BOLD} />
      </Toggle>
    </MinimizedFeedContainer>
  );

  return (
    <AccountActivityFeedContainer
      ref={wrapperRef}
      onScroll={event => {
        event.stopPropagation();
      }}
    >
      <Collapsible trigger={MinimizedFeed} open={isOpen} transitionTime={75}>
        <FeedContainer>
          {activities.length === 0 ? (
            EmptyFeed
          ) : (
            <>
              <Feed
                activities={activities}
                linkToProperty={true}
                truncateComments={true}
                allowEdits={false}
                feedSource="Account"
                onUpdate={onUpdate}
              />
              <ViewMoreContainer>
                {data?.accountActivities.pageInfo.nextPage && (
                  <Button
                    disabled={networkStatus === NetworkStatus.loading}
                    styleVariant="secondary"
                    size="small"
                    onClick={() => {
                      setPage(page + 1);
                      track("User loaded more activity", {
                        userId: user?.id ?? admin?.id,
                        userEmail: user?.email ?? admin?.email,
                      });
                    }}
                  >
                    View more
                  </Button>
                )}
              </ViewMoreContainer>
            </>
          )}
        </FeedContainer>
      </Collapsible>
    </AccountActivityFeedContainer>
  );
};

export default AccountActivityFeed;
