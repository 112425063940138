import React, { useContext, useState } from "react";
import FullPageFormLayout from "../../../../Common/FullPageFormLayout";
import { getPath } from "common/routing";
import { useHistory, useParams } from "react-router";
import {
  DfeRule,
  GetPropertyInformationSectionQuery,
  useCreateCustomAccountPropertyAttributeMutation,
  useGetPropertyInformationSectionQuery,
  useUpdatePropertyInformationSectionMutation,
} from "../../../../../generated/graphql";
import { FormProvider, useForm } from "react-hook-form";
import TabGroup from "../../../../Common/TabGroup";
import { FlexColumn, FlexRow } from "../../../../Common/__styles__/Layout";
import { TabGroupContainer } from "../../../../Common/__styles__/TabGroup";
import { SectionSettings } from "./SectionSettings";
import { TabBody } from "./__styles__/EditSection";
import { Button } from "../../../../Common/Button";
import { Icon } from "../../../../Common/Icons/LucideIcons";
import { Title, Body } from "../../../../Common/Typography";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import SectionAttributesTable from "./SectionAttributesTable";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { useUpdateOrCreateAttributeModal } from "./updateOrCreateSectionAttributeModal";

export type EditSectionFormStructure = {
  label: string;
  displayToPublic: boolean;
  isPii: boolean;
};

enum PROPERTY_OVERVIEW_SECTION_TABS {
  ATTRIBUTES = "attributes",
  SETTINGS = "settings",
}

export const EditSection = ({
  account,
  propertyInformationSection,
  onUpdate,
}: {
  account: NonNullable<GetPropertyInformationSectionQuery["account"]>;
  propertyInformationSection: NonNullable<
    GetPropertyInformationSectionQuery["propertyInformationSection"]
  >;
  onUpdate: () => void;
}) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const exit = () => history.push(getPath("propertyData"));
  const [updatePropertyInformationSection, { loading }] =
    useUpdatePropertyInformationSectionMutation({
      onCompleted: () => {
        addSuccessToast("Property overview section updated");
        exit();
      },
      onError: () => {
        addErrorToast("Failed to update property overview section");
      },
    });
  const { account: contextAccount } = useContext(AuthContext);

  const [createCustomPropertyAttribute] =
    useCreateCustomAccountPropertyAttributeMutation();

  const formMethods = useForm<EditSectionFormStructure>({
    defaultValues: {
      ...propertyInformationSection,
      displayToPublic: !propertyInformationSection.hiddenFromPublic,
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    watch,
  } = formMethods;

  const tabs: Array<{ label: string | JSX.Element; value: string }> = [];

  const isAttributeSection = [
    PROPERTY_INFORMATION_SECTION_CODE.FLOOD_INFO,
    PROPERTY_INFORMATION_SECTION_CODE.BUILDING_INFO,
    PROPERTY_INFORMATION_SECTION_CODE.CUSTOM,
  ].includes(propertyInformationSection.code);

  if (isAttributeSection) {
    tabs.push({
      value: PROPERTY_OVERVIEW_SECTION_TABS.ATTRIBUTES,
      label: "Attributes",
    });
  }

  const allowCreateCustomAttribute =
    propertyInformationSection.code !==
      PROPERTY_INFORMATION_SECTION_CODE.FLOOD_INFO || account.firms.length > 0;

  if (errors.label || errors.displayToPublic) {
    tabs.push({
      value: PROPERTY_OVERVIEW_SECTION_TABS.SETTINGS,
      label: (
        <span>
          Settings
          <Icon
            color="contentCritical"
            iconName={"info"}
            size={16}
            style={{ verticalAlign: "sub" }}
          />
        </span>
      ),
    });
  } else {
    tabs.push({
      value: PROPERTY_OVERVIEW_SECTION_TABS.SETTINGS,
      label: "Settings",
    });
  }

  const [tab, setTab] = useState<string>(tabs[0]!.value);

  const [showCreateCustomPropertyAttribute] = useUpdateOrCreateAttributeModal({
    onSubmit: createCustomPropertyAttribute,
    onUpdate: () => {
      onUpdate();
      setTab(PROPERTY_OVERVIEW_SECTION_TABS.ATTRIBUTES);
    },
    sectionId: propertyInformationSection.id,
    rasterOptions: account.rasters,
    customMapOptions: account.customMaps,
  });

  const onSave = async (data: EditSectionFormStructure) => {
    await updatePropertyInformationSection({
      variables: {
        data: {
          id: propertyInformationSection!.id,
          hiddenFromPublic: !data.displayToPublic,
          isPii: data.isPii,
          label: data.label,
        },
      },
    });
  };

  const rightContainer = (
    <FlexRow style={{ gap: "16px" }}>
      <Button styleVariant="secondary" size="small" onClick={exit}>
        Cancel
      </Button>
      <Button
        styleVariant="primary"
        size="small"
        loading={loading}
        onClick={handleSubmit(onSave)}
      >
        Save
      </Button>
    </FlexRow>
  );

  const formLabelValue = watch("label");
  const sectionName = formLabelValue || "Section name";

  let subtitle: string;
  if (
    propertyInformationSection.code ===
      PROPERTY_INFORMATION_SECTION_CODE.PARCEL_INFO ||
    propertyInformationSection.code ===
      PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS
  ) {
    subtitle = "Edit section name and/or display settings.";
  } else {
    if (contextAccount?.publicPortal.enabled) {
      subtitle =
        "Add and/or edit attributes within the property information panel for both internal and public facing sites.";
    } else {
      subtitle =
        "Add and/or edit attributes within the property information panel.";
    }
  }

  return (
    <FullPageFormLayout
      subtitle={sectionName}
      prevLocation={getPath("propertyData")}
      rightContainer={rightContainer}
      width="regular"
    >
      <FlexColumn style={{ marginTop: "24px", maxWidth: "100%" }}>
        <FlexRow style={{ justifyContent: "space-between", gap: "24px" }}>
          <FlexColumn>
            <div style={{ marginBottom: "auto" }}>
              <Title
                size={"small"}
                type={"regular"}
                color={formLabelValue ? "contentPrimary" : "contentSecondary"}
              >
                {sectionName}
              </Title>
            </div>
            <Body
              size="default"
              type="regular"
              color="contentSecondary"
              style={{ marginBottom: "32px" }}
            >
              {subtitle}
            </Body>
          </FlexColumn>

          {isAttributeSection && (
            <div style={{ minWidth: "138px" }}>
              <Button
                styleVariant="outlineLight"
                size="small"
                leftIconName="plus"
                disabled={!allowCreateCustomAttribute}
                onClick={() =>
                  showCreateCustomPropertyAttribute({
                    rasterOptions: account.rasters,
                  })
                }
              >
                Add attribute
              </Button>
            </div>
          )}
        </FlexRow>

        <TabGroupContainer>
          <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} color="grey" />
        </TabGroupContainer>
        <FormProvider {...formMethods}>
          <TabBody>
            {tab === PROPERTY_OVERVIEW_SECTION_TABS.ATTRIBUTES && (
              <SectionAttributesTable
                currentData={{
                  accountPropertyAttributes:
                    propertyInformationSection.accountPropertyAttributes,
                  ruleDefinitions: [
                    {
                      rules: (account.dfeRuleDefinition[0]?.rules ??
                        []) as Array<DfeRule>,
                    },
                  ],
                }}
                sectionId={propertyInformationSection!.id}
                rasterOptions={account.rasters}
                customMapOptions={account.customMaps}
                onUpdate={onUpdate}
              />
            )}

            {/* Removing this section from the dom removes the form errors. By hiding it instead of removing it,
            we can still prevent the form from being submitted when there's an error */}
            <div
              style={{
                display:
                  tab === PROPERTY_OVERVIEW_SECTION_TABS.SETTINGS
                    ? "block"
                    : "none",
              }}
            >
              <SectionSettings code={propertyInformationSection.code} />
            </div>
          </TabBody>
        </FormProvider>
      </FlexColumn>
    </FullPageFormLayout>
  );
};

export default () => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const { account } = useContext(AuthContext);

  const { data, loading, refetch } = useGetPropertyInformationSectionQuery({
    variables: { sectionId, accountId: account!.id },
  });

  if (loading || !data?.propertyInformationSection || !data.account) {
    return null;
  }

  return (
    <EditSection
      propertyInformationSection={data.propertyInformationSection}
      account={data.account}
      onUpdate={refetch}
    />
  );
};
