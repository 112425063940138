import React, { useContext } from "react";
import { Body } from "../../../../Common/Typography";
import { Required } from "../../../../Inputs/__styles__/Label";
import { useFormContext } from "react-hook-form";
import { EditSectionFormStructure } from "./EditSection";
import { Checkbox, Text } from "../../../../Inputs/react-hook-form";
import { FlexColumn, FlexRow } from "../../../../Common/__styles__/Layout";
import { Label } from "../../../../Inputs";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { AuthContext } from "../../../../Authorization/AuthContext";

export const SectionSettings = ({
  code,
}: {
  code: PROPERTY_INFORMATION_SECTION_CODE;
}) => {
  const { account } = useContext(AuthContext);
  const {
    formState: { errors },
    register,
  } = useFormContext<EditSectionFormStructure>();

  const isRepLoss = code === PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS;
  const publicPortalEnabled = account?.publicPortal.enabled;

  const repLossDisplaySettings = (
    <FlexRow style={{ gap: "8px" }}>
      <Checkbox id="isPii" disabled={true} checked={true} />
      <Label
        text="Personally Identifiable Information"
        htmlFor="isPii"
        disabled={true}
      />
    </FlexRow>
  );

  return (
    <FlexColumn style={{ gap: "16px" }}>
      <Body size={"large"} type={"emphasis"}>
        All fields are required<Required>*</Required>
      </Body>
      <Text
        {...register("label", { required: "This is a required field" })}
        label="Section name"
        error={errors.label?.message}
      />
      {(publicPortalEnabled || isRepLoss) && (
        <FlexColumn>
          <Label text="Display setting" />
          {isRepLoss && repLossDisplaySettings}
          {publicPortalEnabled && (
            <FlexRow style={{ gap: "8px" }}>
              <Checkbox
                id="displayToPublic"
                {...register("displayToPublic")}
                disabled={isRepLoss}
              />
              <FlexColumn style={{ gap: "4px" }}>
                <Label
                  text="Display on public website"
                  htmlFor="displayToPublic"
                  disabled={isRepLoss}
                  style={{ marginBottom: "0px" }}
                />
                <Body color="contentSecondary" size="default" type="regular">
                  Attribute display values are maintained but overridden if
                  section is hidden
                </Body>
              </FlexColumn>
            </FlexRow>
          )}
        </FlexColumn>
      )}
    </FlexColumn>
  );
};
