import React from "react";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { useModal } from "react-modal-hook";
import { EditableMode, pickZones } from "common-client/utils/firms";
import { formatFeet, truncateStringDecimal } from "common/utils/strings";

import Modal from "../../../Common/Modal";

import {
  UpdatePropertyFirmMutationVariables,
  useUpdatePropertyFirmMutation,
} from "../../../../generated/graphql";

import { InputRow, OptionsWrapper } from "../../../Common/__styles__/Modal";
import SelectionCardGroup from "../../../Inputs/SelectionCardGroup";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { isNil } from "lodash";
import { Property } from "./types";
import { IconNames } from "../../../Common/Icons/LucideIcons";
import { Body } from "../../../Common/Typography";
import { ReactHookFormTextInput } from "../../../Inputs/Text";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";
import { CommonModal } from "../../../Common/CommonModal";
import { useForm } from "react-hook-form";
import { FIRM_UPDATE_REFECTH_QUERIES } from "./FIRMEditModal";

type SelectionCardOption = {
  id: string;
  value: string;
  title: string;
  description: string;
  iconName: IconNames;
};

const getCleanInputValue = (
  value: Maybe<string>,
  options: SelectionCardOption[]
) => {
  const isCustomValue = !options.find(option => option.value === value);
  return isCustomValue ? truncateStringDecimal({ value }) : value;
};

type ModeDispatch<Option> = {
  title: string;
  explanatoryText: (map: string) => string;
  textInputLabel: string;
  iconName: IconNames;
  generateOptions: (firm: FIRMWithWarnings) => SelectionCardOption[];
  findStartingOption: (args: {
    firm: FIRMWithWarnings;
    options: Array<Option>;
  }) => Maybe<string>;
  generateMutationData: (
    value: Maybe<string>,
    options: SelectionCardOption[]
  ) => UpdatePropertyFirmMutationVariables["data"];
  generateSecondaryActions?: (
    openApproximateBfeTool?: () => void
  ) => Array<{ text: string; onClick: () => void }>;
};

const elevationMode: ModeDispatch<Pick<FIRMWithWarnings, "stringStaticBFE">> = {
  textInputLabel: "Base Flood Elevation (ft)",
  iconName: "waves",
  title: `Base Flood Elevation`,
  explanatoryText: firmName => firmName,
  generateOptions: firm => {
    return pickZones(
      firm.zones
        .filter(zone => zone.floodzone === firm.floodzone)
        .filter(zone => !isNil(zone.stringStaticBFE))
    ).map((zone, index) => {
      return {
        id: `${zone.stringStaticBFE!}-${index}`,
        value: zone.stringStaticBFE!,
        title: formatFeet(zone.stringStaticBFE),
        description: `Zone ${zone.floodzone}`,
        iconName: "waves",
      };
    });
  },
  findStartingOption: ({ firm }) => {
    return firm.stringStaticBFE ?? null;
  },
  generateMutationData: (value, options) => ({
    stringStaticBFE: getCleanInputValue(value, options),
  }),
  generateSecondaryActions: openApproximateBfeTool => {
    if (!openApproximateBfeTool) return [];
    return [
      {
        text: "Calculate approximate BFE",
        onClick: openApproximateBfeTool,
      },
    ];
  },
};

const depthMode: ModeDispatch<Pick<FIRMWithWarnings, "stringDepth">> = {
  textInputLabel: "Base Flood Depth (ft)",
  iconName: "waves",
  title: `Base Flood Depth`,
  explanatoryText: firmName => firmName,
  generateOptions: firm => {
    return pickZones(
      firm.zones
        .filter(zone => zone.floodzone === firm.floodzone)
        .filter(zone => !isNil(zone.stringDepth))
    ).map((zone, index) => {
      return {
        id: `${zone.stringDepth!}-${index}`,
        value: zone.stringDepth!,
        title: formatFeet(zone.stringDepth),
        description: `Zone ${zone.floodzone}`,
        iconName: "waves",
      };
    });
  },
  findStartingOption: ({ firm }) => {
    return firm.stringDepth ?? null;
  },
  generateMutationData: (value, options) => ({
    stringDepth: getCleanInputValue(value, options),
  }),
};

export const BASE_FLOOD_MODES: Record<
  BaseFloodEditProps["mode"],
  ModeDispatch<any>
> = {
  elevation: elevationMode,
  depth: depthMode,
};

export interface BaseFloodEditProps {
  property: NonNullable<Property>;
  firm?: FIRMWithWarnings;
  mode: Extract<EditableMode, "elevation" | "depth">;
  onCancel: () => void;
  onSave: () => void;
  refetchQueries?: string[];
  openApproximateBfeTool?: () => void;
}

interface FormState {
  value: Maybe<string>;
}

export const BaseFloodEditModal = ({
  property,
  firm,
  mode,
  onCancel,
  onSave,
  openApproximateBfeTool,
  refetchQueries = FIRM_UPDATE_REFECTH_QUERIES,
}: BaseFloodEditProps) => {
  if (!firm) return null;

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const propertyId = property.id;

  let {
    generateOptions,
    findStartingOption,
    title,
    textInputLabel,
    explanatoryText,
    generateMutationData,
    generateSecondaryActions,
  } = BASE_FLOOD_MODES[mode];

  const options = generateOptions(firm);
  const startingValue = findStartingOption({ firm, options: options as any });

  const handleComplete = () => {
    onSave();
    addSuccessToast(`${firm.name} data successfully updated`);
  };

  const [updateFIRM, { loading }] = useUpdatePropertyFirmMutation({
    onCompleted: handleComplete,
    refetchQueries,
    awaitRefetchQueries: true,
    onError: () => {
      addErrorToast(
        `There was an issue updating the ${firm.name} data. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const onSubmit = async (formData: FormState) => {
    const data = generateMutationData(formData.value, options);
    await updateFIRM({
      variables: {
        propertyId: propertyId,
        firmId: firm.id,
        data,
      },
    });
  };

  const subtitle = explanatoryText(firm.name);
  const secondaryButtons =
    generateSecondaryActions?.(openApproximateBfeTool) ?? [];

  const { handleSubmit, register, setValue, watch } = useForm<FormState>({
    defaultValues: {
      value: startingValue,
    },
  });

  const currentValue = watch("value");

  const handleOptionChange = (value: string) => {
    setValue("value", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <CommonModal
      dataTestId="base-flood-edit-modal"
      title={title}
      subtitle={subtitle}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      loading={loading}
      size="small"
      secondaryButtons={secondaryButtons}
      content={
        <>
          <InputRow>
            <ReactHookFormTextInput
              {...register("value")}
              label={textInputLabel}
              tabIndex={0}
              type="number"
              step="0.1"
            />
          </InputRow>
          {arrayHasAtLeastOneItem(options) && (
            <OptionsWrapper>
              <Body size="small" type="emphasis" color={"contentPlaceholder"}>
                MAP DATA FOR SELECTED ZONE
              </Body>
              <SelectionCardGroup
                onChange={handleOptionChange}
                options={options}
                value={currentValue ?? undefined}
                data-orientation="vertical"
                spacingStyle="compact"
              />
            </OptionsWrapper>
          )}
        </>
      }
    />
  );
};

export const useEditBaseFloodModal = ({
  onSave,
  onCancel,
  ...props
}: BaseFloodEditProps) => {
  const [show, hide] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hide();
          onCancel();
        }}
      >
        <BaseFloodEditModal
          onCancel={() => {
            hide();
            onCancel();
          }}
          onSave={() => {
            hide();
            onSave();
          }}
          {...props}
        />
      </Modal>
    ),
    [props, onSave, onCancel]
  );

  return [show, hide] as const;
};
