import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../../DocumentUploads/ActionButton";

import {
  AccountDocumentType as DeprecatedADT,
  DocumentType,
  CertificateUploadStatus,
} from "../../../generated/graphql";
import { ViewDocumentLink } from "./ViewDocumentLink";
import DocumentUploadIcon from "./DocumentUploadIcon";
import { MIME_TYPE, UserFriendlyCertificateStatus } from "common/constants";
import { buildLink } from "common/routing";
import {
  Row,
  TextContainer,
  Title,
  Subtitle,
  Bullet,
  TitleContainer,
  LinkTruncateWrapper,
} from "./__styles__/ListItem";
import { StyledLink } from "../Activity/__styles__/Message";
import {
  documentUploadDisabled,
  formatDocumentUploadName,
} from "common-client/utils/documentUploads";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { TooltipWithText } from "../../Common/Tooltip";

// TODO: Remove this new type and use "DeprecatedADT" once we remove
// documentType.isSensitive from the schema entirely
type AccountDocumentType = Omit<DeprecatedADT, "documentType"> & {
  documentType?: Maybe<Omit<DocumentType, "isSensitive">>;
};

export interface ListItemProps {
  documentUploadId: string;
  documentUploadMimeType: MIME_TYPE;
  elevationCertificateId?: string;
  formData?: Record<string, string>;
  createdAt: string;
  issuedAt: string;
  hiddenFromPublic: boolean;
  accountDocumentType: AccountDocumentType;
  filename: string;
  pathname?: string;
  search?: string;
  status?: Maybe<CertificateUploadStatus>;
  submission?: Maybe<{
    id: string;
    submissionTypeVersion: { submissionType: { name: string } };
  }>;
  isDeletable: boolean;
  propertyId?: Maybe<string>;
  onUpdate?: () => void;
}

const DynamicOverflowWrapper = ({
  Link,
  linkProps,
  text,
}: {
  Link: React.ElementType;
  linkProps: any;
  text: string;
}) => {
  const { width } = useWindowSize();
  const elementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState<any>(false);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      // taken from this s/o https://stackoverflow.com/a/59364492/5094136
      setIsOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [width]);

  return (
    <LinkTruncateWrapper ref={elementRef}>
      <Link {...linkProps}>
        <TooltipWithText
          hoverText={text}
          tooltipText={text}
          disable={!isOverflow}
        />
      </Link>
    </LinkTruncateWrapper>
  );
};

export default ({
  documentUploadId,
  documentUploadMimeType,
  elevationCertificateId,
  formData,
  createdAt,
  issuedAt,
  hiddenFromPublic,
  accountDocumentType,
  filename,
  pathname,
  search,
  status,
  submission,
  onUpdate,
  propertyId,
  isDeletable,
}: ListItemProps) => {
  const prevLocation = `${pathname}${search}`;

  const cancelled = status === CertificateUploadStatus.CANCELED;
  const disabled = documentUploadDisabled({
    cancelled,
    status: status || null,
  });

  const linkProps = {
    documentUploadId,
    elevationCertificateId,
    accountDocumentType,
    prevLocation,
    disabled,
  };

  const property = propertyId ? { id: propertyId } : null;

  const documentUpload = {
    id: documentUploadId,
    issuedAt,
    submission,
    accountDocumentType,
    originalFilename: filename,
    property,
    hiddenFromPublic,
    isDeletable,
  };

  const documentUploadTitle = formatDocumentUploadName({
    isSubmission: !!formData,
    createdAt,
    issuedAt,
    accountDocumentTypeName: accountDocumentType.name,
  });

  let fileNameText = filename;
  if (disabled || cancelled) {
    fileNameText += ` - ${UserFriendlyCertificateStatus[status!]}`;
  }

  return (
    <Row>
      <TitleContainer>
        <DocumentUploadIcon documentUploadMimeType={documentUploadMimeType} />
        <TextContainer>
          <Title disabled={disabled}>{documentUploadTitle}</Title>
          <Subtitle>
            <DynamicOverflowWrapper
              Link={ViewDocumentLink}
              linkProps={linkProps}
              text={fileNameText}
            />

            {/* Because we are using flex, I need the bullet and the submission link to be siblings with the document name */}
            {!!submission && <Bullet>&#9679;</Bullet>}
            {!!submission && (
              <DynamicOverflowWrapper
                Link={StyledLink}
                linkProps={{
                  to: {
                    pathname: buildLink("viewSubmission", {
                      submissionId: submission.id,
                    }),
                    state: { prevLocation },
                  },
                  "data-testid": "view-submission-link",
                }}
                text={submission.submissionTypeVersion.submissionType.name}
              />
            )}
          </Subtitle>
        </TextContainer>
      </TitleContainer>
      <ActionButton
        documentUpload={documentUpload}
        disabled={disabled}
        onUpdate={onUpdate}
      />
    </Row>
  );
};
