import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../Common/Button";
import { Text } from "../../Inputs/react-hook-form";

import { Section } from "../__styles__/Content";
import { PHONE_REGEX, isValidEmail } from "common/utils/strings";
import { pick } from "lodash";

interface FormStructure {
  name?: Maybe<string>;
  email?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  title?: Maybe<string>;
  homepageTitle: string;
  navBarTitle: string;
}

export const PublicWebsiteSettingsForm = ({
  settings,
  canUpdateAccountInformation,
  onUpdate,
}: {
  settings: FormStructure;
  canUpdateAccountInformation: boolean;
  onUpdate: ({ variables }: { variables: { data: FormStructure } }) => void;
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormStructure>({
    defaultValues: pick(settings, [
      "name",
      "email",
      "phoneNumber",
      "title",
      "homepageTitle",
      "navBarTitle",
    ]),
  });

  const validateTitleMessage =
    "If title is provided you must provide either a name, phone, or email";

  return (
    <form
      onSubmit={handleSubmit(data => {
        onUpdate({ variables: { data } });
      })}
    >
      <Section>
        <h2>Public website settings</h2>
        <p>
          This contact information will appear at the top of your public
          website's Get Help page for residents to reach out directly.
        </p>
      </Section>
      <Section grid>
        <Text label="Name" error={errors.name?.message} {...register("name")} />
        <Text
          label="Title"
          error={errors.title?.message}
          {...register("title", {
            validate: (val: Maybe<string | undefined>) => {
              if (
                val &&
                !getValues("name") &&
                !getValues("email") &&
                !getValues("phoneNumber")
              ) {
                return validateTitleMessage;
              }
              return;
            },
          })}
        />
        <Text
          label="Phone"
          error={errors.phoneNumber?.message}
          {...register("phoneNumber", {
            validate: (val: Maybe<string | undefined>) => {
              if (val && !PHONE_REGEX.test(val)) {
                return "Phone number is invalid. Please use the following format: 123-456-7890";
              }
              return;
            },
          })}
        />
        <Text
          label="Email"
          error={errors.email?.message}
          {...register("email", {
            validate: (val: Maybe<string | undefined>) => {
              if (val && !isValidEmail(val)) {
                return "Email is invalid";
              }
              return;
            },
          })}
        />
        <Text
          label="Navigation bar title"
          error={errors.navBarTitle?.message}
          {...register("navBarTitle", {
            maxLength: {
              value: 25,
              message: "Navigation bar title must be less than 25 characters",
            },
          })}
        />
        <Text
          label="Homepage title"
          error={errors.homepageTitle?.message}
          {...register("homepageTitle", {
            maxLength: {
              value: 60,
              message: "Homepage title must be less than 60 characters",
            },
          })}
        />
      </Section>
      <Section>
        <Button
          styleVariant="primary"
          size="medium"
          type="submit"
          disabled={!canUpdateAccountInformation}
        >
          Update public website settings
        </Button>
      </Section>
    </form>
  );
};
