import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { buildLink } from "common/routing";
import { accountTitle, useIsMobileWidth } from "./utils";
import { AuthContext } from "../Authorization/AuthContext";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import { DropdownMenu } from "../Inputs";
import { Button } from "../Common/Button";

import { ActionsProps } from "../Inputs/DropdownMenu";
import { getCurrentURL } from "../../utils/url";

import {
  AccountLogo,
  FooterContainer,
  HeaderContainer,
  HeaderContent,
  MenuItem,
  MenuParent,
  Page,
  StyledLink,
  TextContainer,
} from "./__styles__/Layout";

interface ElevationCertificatesLink {
  onClick: (_: React.MouseEvent<HTMLElement>) => void;
}
const ElevationCertificatesLink = ({ onClick }: ElevationCertificatesLink) => {
  return (
    <Button
      styleVariant="ghostWhite"
      size="medium"
      onClick={onClick}
      rightIconName="chevron-down"
    >
      Elevation Certificates
    </Button>
  );
};

const CertificateMenuOptions = ({
  supportsDocumentUploads,
  accountId,
}: {
  supportsDocumentUploads: boolean;
  accountId: string;
}) => {
  const isMobile = useIsMobileWidth();
  const location = useLocation();

  const actions: Array<ActionsProps> = [
    {
      label: "View Elevation Certificates",
      to: {
        pathname: buildLink("guestElevationCertificates"),
      },
      variant: "underlined",
    },
    {
      label: "Upload Elevation Certificates",
      to: {
        pathname: buildLink("guestUploadElevationCertificates"),
        state: { prevLocation: getCurrentURL(location) },
      },
      variant: "underlined",
    },
  ];

  if (supportsDocumentUploads) {
    return isMobile ? (
      <>
        {actions.map((action, index) => {
          return (
            <StyledLink to={action.to!} key={index}>
              {action.label}
            </StyledLink>
          );
        })}
      </>
    ) : (
      <DropdownMenu
        actions={actions}
        customButton={ElevationCertificatesLink}
        position={"below-right"}
      />
    );
  }

  return (
    <StyledLink to={buildLink("guestElevationCertificates", { accountId })}>
      Elevation Certificates
    </StyledLink>
  );
};

const MenuItems = ({
  accountId,
  onGetHelpClick,
}: {
  accountId: string;
  onGetHelpClick: () => void;
}) => {
  const account = useContext(AuthContext).account!;

  return (
    <MenuItem>
      <CertificateMenuOptions
        supportsDocumentUploads={account.publicPortal.supportsDocumentUploads}
        accountId={accountId}
      />
      <Button
        onClick={onGetHelpClick}
        styleVariant="secondary"
        size="medium"
        leftIconName="info"
        style={{ minWidth: "unset", whiteSpace: "nowrap" }}
      >
        Get help
      </Button>
    </MenuItem>
  );
};

const MenuButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    onClick={onClick}
    size="small"
    style={{
      border: "none",
      boxShadow: "none",
      background: "none",
      padding: 0,
      minWidth: "28px",
    }}
    rightIconName="more-horizontal"
    styleVariant="outlineDark"
  />
);

interface HeaderProps {
  propertyId?: string;
}

export const Header = ({ propertyId }: HeaderProps) => {
  const history = useHistory();
  const isMobile = useIsMobileWidth();
  const [menuOpen, setMenuOpen] = useState(false);
  const wrapperRef = useRef(null);
  const account = useContext(AuthContext).account!;

  useEffect(() => {
    setMenuOpen(false);
  }, [isMobile]);

  //when someone clicks outside of the mobile menu the menu will dissapear
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setMenuOpen(false),
  });

  const onGetHelpClick = () => {
    history.push(
      buildLink("getHelp", { accountId: account.id }, { propertyId })
    );
  };

  const subtitle = account.publicPortal.navBarTitle;

  return (
    <HeaderContainer
      menuOpen={menuOpen}
      style={{ background: account.publicPortal.color }}
      ref={wrapperRef}
      id="header"
    >
      <HeaderContent>
        <TextContainer to={buildLink("guestHome", { accountId: account.id })}>
          {account.logoUrl && (
            <AccountLogo src={account.logoUrl} alt={`${account.name} Logo`} />
          )}
          <div>
            <h1 data-testid="resident-main-header">{accountTitle()}</h1>
            <h2 data-testid="resident-subheader">{subtitle}</h2>
          </div>
        </TextContainer>
        {isMobile ? (
          <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
        ) : (
          <MenuItems accountId={account.id} onGetHelpClick={onGetHelpClick} />
        )}
      </HeaderContent>
      {menuOpen && (
        <MenuParent style={{ background: account.publicPortal.color }}>
          <MenuItems accountId={account.id} onGetHelpClick={onGetHelpClick} />
        </MenuParent>
      )}
    </HeaderContainer>
  );
};

export const Footer = () => {
  return (
    <FooterContainer id="footer">
      <a href="https://www.withforerunner.com/" target="_blank">
        Powered by Forerunner
      </a>{" "}
      |{" "}
      <a href="https://www.withforerunner.com/terms-of-service" target="_blank">
        Terms of Service
      </a>
    </FooterContainer>
  );
};

type LayoutProps = {
  propertyId?: string;
  children: ReactNode;
};

const Layout = ({ propertyId, children }: LayoutProps) => {
  return (
    <Page>
      <Header propertyId={propertyId} />
      {children}
      <Footer />
    </Page>
  );
};

export default Layout;
