import React from "react";
import { useModal } from "react-modal-hook";
import { toLower } from "lodash";

import { useUpdatePropertyHiddenFromPublicMutation } from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import Modal from "../../Common/Modal";
import { Button } from "../../Common/Button";
import { AddressPanelProperty } from "../utils";

import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

export interface HidePropertyModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  property: AddressPanelProperty;
}

export const useHidePropertyModal = ({
  onSubmit,
  onCancel,
  property,
}: HidePropertyModalProps) => {
  const [showHidePropertyModal, closeHidePropertyModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          onCancel();
        }}
      >
        <HidePropertyConfirmation
          onCancel={onCancel}
          property={property}
          onSubmit={onSubmit}
        />
      </Modal>
    ),
    [property]
  );

  return [showHidePropertyModal, closeHidePropertyModal] as const;
};

export const HidePropertyConfirmation = ({
  onSubmit,
  onCancel,
  property,
}: HidePropertyModalProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();

  if (!property) {
    throw Error("Missing Property");
  }

  const hide = property.hiddenFromPublic;

  const hideOrDisplay = hide ? "Display" : "Hide";

  const [updatePropertyHiddenFromPublic, { loading }] =
    useUpdatePropertyHiddenFromPublicMutation({
      onCompleted: () => {
        onSubmit();

        addSuccessToast(
          `The property was successfully ${
            hide ? "hidden from" : "displayed to"
          } public`
        );
      },
      onError: () => {
        addErrorToast(
          `There was an error ${
            hide ? "hiding" : "displaying"
          } this property. Please try again. If the problem persists, please email support@withforerunner.com`
        );
      },
    });

  const toggleHidden = async () => {
    if (loading) return;
    track(`Property ${hide ? "displayed" : "hidden"}`, {
      streetAddress: property.streetAddress,
      city: property.city,
    });
    await updatePropertyHiddenFromPublic({
      variables: {
        id: property.id,
        data: { hide: !property.hiddenFromPublic },
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>{hideOrDisplay} property</h1>
        <h2>{property.fullAddress}</h2>
      </HeaderSection>
      <ContentSection>
        <p>
          This action will {toLower(hideOrDisplay)} the {property.streetAddress}{" "}
          property and all associated data and files on the public website.{" "}
          {hide
            ? "Members of the public will be able to search for and click on the property on the map."
            : "Members of the public attempting to search or click on the property on the map will find no results."}
        </p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button
            size="medium"
            styleVariant={"outlineLight"}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            styleVariant={"alert"}
            onClick={toggleHidden}
            disabled={loading}
          >
            I understand, {toLower(hideOrDisplay)} this property
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
