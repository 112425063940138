import React from "react";
import { useLocation } from "react-router-dom";
import { buildLink } from "common/routing";
import { Submission, SubmissionType } from "../../../generated/graphql";
import { formatDateString } from "../../../utils/dates";
import ActionButton from "../../Submissions/ActionButton";
import { Row, Subtitle, Title } from "../DocumentUploads/__styles__/ListItem";
import { SubmissionLink } from "./__styles__/Submissions";
import { hasSummary } from "../../Submissions/utils";
import { MODULE_CONFIGURATIONS } from "../../Submissions/modules";
import { isNotNil } from "common/utils/tools";

type SubmissionTypeVersion = {
  submissionType: Pick<SubmissionType, "name" | "category" | "modules">;
};

export type SubmissionListItemProps = {
  submission: Pick<Submission, "id" | "createdAt" | "updatedAt" | "status"> & {
    summaries: Array<{ __typename: string }>;
    submissionTypeVersion: SubmissionTypeVersion;
    relatedSubmissions: Array<
      Pick<Submission, "id"> & {
        submissionTypeVersion: SubmissionTypeVersion;
      }
    >;
  };
  onUpdate: () => void;
};

export default ({ submission, onUpdate }: SubmissionListItemProps) => {
  const { pathname, search } = useLocation();

  const prevLocation = `${pathname}${search}`;
  const dateText =
    submission.createdAt === submission.updatedAt ? "Submitted" : "Last edited";

  const listItemDescription =
    submission.submissionTypeVersion.submissionType.modules
      .map(module => {
        return MODULE_CONFIGURATIONS[module]?.buildListItemDescription(
          submission
        );
      })
      .filter(isNotNil)
      .join(" - ");

  return (
    <Row>
      <SubmissionLink
        to={{
          pathname: buildLink("viewSubmission", {
            submissionId: submission.id,
          }),
          state: { prevLocation },
        }}
      >
        <div style={{ margin: "8px 0" }}>
          <Title>{submission.submissionTypeVersion.submissionType.name}</Title>
          <Subtitle>
            {dateText}{" "}
            {formatDateString({
              format: "MM/DD/YYYY",
              dateString: submission.updatedAt,
            })}{" "}
            {listItemDescription && `- ${listItemDescription}`}
          </Subtitle>
        </div>
      </SubmissionLink>

      <ActionButton
        submission={{
          ...submission,
          hasSummary: hasSummary(submission),
          category: submission.submissionTypeVersion.submissionType.category,
        }}
        onUpdate={onUpdate}
      />
    </Row>
  );
};
