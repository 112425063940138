import React from "react";

import { CustomText } from "../../../../../../Submissions/Widgets";

import BaseWidgetPreview from "./BaseWidgetPreview";
import { WidgetProps } from "@rjsf/utils";

const TextWidgetPreview = (props: WidgetProps) => {
  const widgetPreview = <CustomText {...props} />;
  return <BaseWidgetPreview widgetPreview={widgetPreview} {...props} />;
};

export default TextWidgetPreview;
