import React, { ReactNode, useEffect, useState } from "react";
import { arrayHasAtLeastOneItem } from "common/utils/arrays";

import { Body, Title } from "./Typography";
import { Button } from "./Button";
import { IconButton } from "./Icons/LucideIcons";
import { handleKeyDown } from "../../utils/accessibility";
import { spacing } from "../../stitches.config";

import { FlexColumn, FlexRow } from "./__styles__/Layout";
import {
  ButtonSection,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
  UpdatedContainer as Container,
} from "./__styles__/Modal";

type MultiStepContent = (props: {
  handleNext?: () => void;
  handlePrevious?: () => void;
}) => ReactNode;

export type CommonModalComponentProps = {
  title: string;
  subtitle?: string;
  size?: "small" | "medium" | "large";
  content: ReactNode | MultiStepContent;
  onSubmit?: () => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
  primaryButtonText?: string;
  buttonLinks?: { text: string; onClick: () => void }[];
  variant?: "primary" | "destructive";
  dataTestId?: string;
  secondaryButtons?: { text: string; onClick: () => void }[];
  overflows?: boolean;
};

export type ModalStep = Pick<
  CommonModalComponentProps,
  | "title"
  | "subtitle"
  | "content"
  | "onSubmit"
  | "onCancel"
  | "loading"
  | "disabled"
  | "primaryButtonText"
  | "overflows"
>;

const ModalContent = ({
  title,
  subtitle,
  content,
  onSubmit,
  onCancel,
  loading = false,
  disabled,
  primaryButtonText,
  variant = "primary",
  secondaryButtons,
  handlePrevious,
  handleNext,
  overflows = true,
}: CommonModalComponentProps & {
  handlePrevious?: () => void;
  handleNext?: () => void;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isStepModal = handlePrevious || handleNext;

  const showButtonSection =
    onSubmit || arrayHasAtLeastOneItem(secondaryButtons);

  content =
    isStepModal && content instanceof Function
      ? content({ handleNext, handlePrevious })
      : content;

  return (
    <>
      <HeaderSection>
        <FlexRow style={{ gap: `${spacing.s}`, paddingRight: `${spacing.xl}` }}>
          {handlePrevious && (
            <IconButton
              testId="backButton"
              iconName={"chevron-left"}
              onClick={handlePrevious}
            />
          )}
          <FlexColumn style={{ gap: `${spacing["2xs"]}` }}>
            <Title size="small" type="semiBold">
              {title}
            </Title>
            {subtitle && (
              <Body type="regular" size="large">
                {subtitle}
              </Body>
            )}
          </FlexColumn>
        </FlexRow>
      </HeaderSection>
      <FormSection overflows={overflows} tabIndex={1}>
        <ContentSection overflows={overflows} css={{ gap: spacing.m }}>
          {content}
        </ContentSection>
        {showButtonSection && (
          <ButtonSection>
            {arrayHasAtLeastOneItem(secondaryButtons) && (
              <SecondaryButtons>
                {secondaryButtons.map(({ text, onClick }) => (
                  <Button
                    key={text}
                    styleVariant="buttonLink"
                    onClick={event => {
                      event.preventDefault();
                      onClick();
                    }}
                    size="medium"
                  >
                    {text}
                  </Button>
                ))}
              </SecondaryButtons>
            )}
            <PrimaryButtons>
              <Button
                styleVariant="secondary"
                onClick={event => {
                  event.preventDefault();
                  onCancel();
                }}
                disabled={loading}
                size="medium"
                onKeyDown={event => {
                  handleKeyDown({ event, keyDownFn: onCancel });
                }}
              >
                Cancel
              </Button>
              <Button
                styleVariant={variant === "destructive" ? "alert" : "primary"}
                onClick={onSubmit}
                disabled={loading || disabled}
                size="medium"
                onKeyDown={event => {
                  handleKeyDown({ event, keyDownFn: onSubmit });
                }}
                type="button"
              >
                {primaryButtonText || "Save"}
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        )}
      </FormSection>
    </>
  );
};

export const CommonModal = ({
  size = "medium",
  onCancel,
  dataTestId,
  overflows = true,
  ...props
}: CommonModalComponentProps & {
  handlePrevious?: () => void;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container size={size} data-testid={dataTestId} overflows={overflows}>
      <ModalContent onCancel={onCancel} overflows={overflows} {...props} />
    </Container>
  );
};

export const MultiStepModal = ({
  steps,
  size,
  onCancel,
  dataTestId,
}: Pick<
  CommonModalComponentProps,
  "size" | "onSubmit" | "onCancel" | "dataTestId"
> & {
  steps: Array<ModalStep>;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleNext = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const renderStep = (index: number) => {
    if (steps[index]) {
      switch (index) {
        //first step
        case 0:
          return <ModalContent {...steps[index]} handleNext={handleNext} />;

        //last step
        case steps.length - 1:
          return (
            <ModalContent
              {...steps[index]}
              handlePrevious={handlePrevious}
              onCancel={onCancel}
            />
          );

        //middle steps
        default:
          return (
            <ModalContent
              {...steps[index]}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          );
      }
    }
    return null;
  };

  return (
    <Container
      size={size}
      data-testid={dataTestId}
      overflows={steps[currentStepIndex]?.overflows}
    >
      {renderStep(currentStepIndex)}
    </Container>
  );
};
