import React, { ChangeEvent } from "react";
import { Label } from "../../../../../../Inputs";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import { CheckboxWrapper } from "./__styles__/WidgetSettings";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";

export const FieldSettingConfig = ({
  fieldPath,
  isModuleInput,
  onRequiredChange,
}: Pick<SelectedSubmissionsBuilderField, "fieldPath"> & {
  isModuleInput: boolean;
  onRequiredChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();
  return (
    <>
      <div>
        <Label text={"Field label"} htmlFor={`${fieldPath}.title`} required />
        <Text {...register(`${fieldPath}.title`)} />
      </div>
      <CheckboxWrapper>
        <Checkbox
          {...register(`${fieldPath}.required`)}
          id={`${fieldPath}.required`}
          onChange={onRequiredChange}
          disabled={isModuleInput}
        />
        <Label
          text={"Required field"}
          htmlFor={`${fieldPath}.required`}
          disabled={isModuleInput}
        />
      </CheckboxWrapper>
      <div>
        <Label text={"Tooltip text"} htmlFor={`${fieldPath}.tooltipText`} />
        <Text {...register(`${fieldPath}.tooltipText`)} />
      </div>
    </>
  );
};
