import React from "react";
import { useModal } from "react-modal-hook";

import {
  UpdatePropertyWarningInput,
  useUpdatePropertyWarningMutation,
} from "../../../generated/graphql";
import { Switch } from "../../Inputs";
import Modal from "../../Common/Modal";

import {
  Container,
  ContentSection,
  HeaderSection,
} from "../../Common/__styles__/Modal";
import WarningList, { EditableWarning } from "../../Warnings/WarningList";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Body, Title } from "../../Common/Typography";

export interface UpdateWarningModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  address?: Maybe<string>;
  propertyId: Maybe<string>;
  warnings: Array<EditableWarning>;
}

export const useUpdateWarningsModal = ({
  onSubmit,
  onCancel,
  address,
  propertyId,
  warnings,
}: UpdateWarningModalProps) => {
  const [showUpdateWarningsModal, hideUpdateWarningsModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          onCancel();
        }}
      >
        <UpdateWarningsModal
          onSubmit={onSubmit}
          onCancel={onCancel}
          address={address}
          propertyId={propertyId}
          warnings={warnings}
        />
      </Modal>
    ),
    [onSubmit, address]
  );

  return [showUpdateWarningsModal, hideUpdateWarningsModal] as const;
};

interface WarningSwitchProps {
  warning: EditableWarning;
  propertyId: string;
  onUpdate: ({
    variables: {
      data: {},
    },
  }: {
    variables: { data: UpdatePropertyWarningInput };
  }) => void;
  loading: boolean;
}

const WarningSwitch = ({
  warning,
  propertyId,
  onUpdate,
  loading,
}: WarningSwitchProps) => {
  const handleToggle = () => {
    onUpdate({
      variables: {
        data: {
          accountPropertyWarningDefinitionId: warning.id,
          propertyId,
          applies: !warning.applies,
        },
      },
    });
  };

  return (
    <Switch
      value={warning.applies!}
      onChange={() => handleToggle()}
      name={warning.title}
      disabled={loading}
      labels="true/false"
      testId={warning.title}
    />
  );
};

export const UpdateWarningsModal = ({
  address,
  propertyId,
  onSubmit,
  warnings,
}: UpdateWarningModalProps) => {
  if (!propertyId) {
    throw Error("Missing property data");
  }

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const [updatePropertyWarning, { loading: updatePropertyWarningLoading }] =
    useUpdatePropertyWarningMutation({
      onCompleted: () => {
        addSuccessToast(`Warning was successfully updated`);
        onSubmit();
      },
      onError: () => {
        addErrorToast(
          `Warning was not able to be updated. Please try again. If the problem persists, please email support@withforerunner.com`
        );
      },
    });

  if (!warnings) {
    return null;
  }

  // TODO(DPI-868): Update subtitle to fallback to something
  // other than "this property"
  const subtitleTarget = address ? address : "this property";

  return (
    <Container width={"wide"}>
      <HeaderSection>
        <Title size="small" type="semiBold">
          Property warnings
        </Title>
        <Body size="large" type="regular">
          Edit property warnings for {subtitleTarget}
        </Body>
      </HeaderSection>
      <ContentSection>
        <WarningList
          warnings={warnings}
          warningRightSection={warning => (
            <WarningSwitch
              warning={warning}
              propertyId={propertyId}
              onUpdate={updatePropertyWarning}
              loading={updatePropertyWarningLoading}
            />
          )}
        />
      </ContentSection>
    </Container>
  );
};
