import React from "react";
import { ColumnDef } from "@tanstack/react-table";

import {
  AccountPropertyAttribute,
  DfeRule,
  useUpdateDfeRuleMutation,
} from "../../../../generated/graphql";
import Table from "../../../Common/Tables/Table";
import { DFE_RULE_TYPE } from "common/constants";
import { DropdownMenu } from "../../../Inputs";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { useUpsertDfeRuleModal } from "./upsertDfeRuleModal";
import { useDeleteDfeRuleModal } from "./deleteDfeRuleModal";
import { getMapFilter } from "common/utils/rules/filters";
import { Filter } from "common/utils/rules/types";

const DeterminationMethodAliasMap = {
  [DFE_RULE_TYPE.AUTOMATIC]: "Automatic",
  [DFE_RULE_TYPE.MANUAL]: "Manual",
  [DFE_RULE_TYPE.MINIMUM]: "Minimum height",
};

type DfeRulesTableData = {
  dfeReferencePropertyAttributes?: Array<
    Pick<AccountPropertyAttribute, "id" | "label">
  >;
  ruleDefinition?: { rules: Array<DfeRule> };
};

const DFERulesTable = ({
  currentData,
  previousData,
  loading,
  onUpdate,
}: {
  currentData?: Maybe<DfeRulesTableData>;
  previousData?: Maybe<DfeRulesTableData>;
  loading: boolean;
  onUpdate: () => void;
}) => {
  const [updateDfeRule] = useUpdateDfeRuleMutation();

  const [showUpsertDfeRuleModal] = useUpsertDfeRuleModal({
    onSubmit: updateDfeRule,
    onUpdate,
  });

  const [showDeleteDfeRuleModal] = useDeleteDfeRuleModal();

  const renderActionButton = (rule: DfeRule) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit rule",
        onClick: () =>
          showUpsertDfeRuleModal({
            referenceAttributes: currentData?.dfeReferencePropertyAttributes,
            existingRule: rule,
          }),
      },
      {
        label: "Delete",
        variant: "red",
        onClick: () => showDeleteDfeRuleModal({ ...rule, onUpdate }),
      },
    ];

    return <DropdownMenu actions={actions} isTableAction />;
  };

  const columns: Array<ColumnDef<DfeRule>> = [
    {
      id: "name",
      header: "Rule name",
      accessorKey: "name",
      enableSorting: false,
    },
    {
      id: "type",
      header: "Determination method",
      accessorKey: "type",
      enableSorting: false,
      cell: ({ row }) => DeterminationMethodAliasMap[row.original.type],
    },
    {
      id: "zones",
      header: "Zone(s)",
      accessorKey: "mapFilterZones",
      enableSorting: false,
      cell: ({ row }) =>
        getMapFilter(row.original.filters as Filter[])?.filter.zones.join(
          ", "
        ) ?? "",
    },
    {
      id: "actions",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: ({ row }) => renderActionButton(row.original),
    },
  ];

  return (
    <Table<DfeRule>
      columns={columns}
      currentData={currentData?.ruleDefinition?.rules ?? []}
      previousData={previousData?.ruleDefinition?.rules ?? []}
      loadingDetails={{
        loading,
        loadingText: "Loading Account DFE Rules...",
        noDataText: "No Account DFE Rules Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      excludePaginationNav
    />
  );
};

export default DFERulesTable;
