import React, { useContext } from "react";
import Table from "../../../../Common/Tables/Table";
import { ColumnDef } from "@tanstack/react-table";
import {
  DfeRule,
  GetAccountPropertyInformationSectionsQuery,
  useUpdatePropertyInformationSectionDisplayOrderMutation,
} from "../../../../../generated/graphql";
import { max } from "lodash";
import DropdownMenu, { ActionsProps } from "../../../../Inputs/DropdownMenu";
import { formatDateString } from "common/utils/dates";
import { colors } from "../../../../../stitches.config";
import { buildLink } from "common/routing";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { useDeleteOverviewSectionModal } from "./deleteOverviewSectionModal";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { RowDragHandleCell } from "../../../../Common/Tables/RowDragHandleCell";

type OverviewSectionsTableProps = {
  id: string;
  label: string;
  hiddenFromPublic: boolean;
  attributeCount: number;
  lastModified: string;
  code: PROPERTY_INFORMATION_SECTION_CODE;
  accountPropertyAttributes: Array<{ id: string }>;
};

const isParcelInfoOrRepetitiveLoss = ({
  code,
}: {
  code: PROPERTY_INFORMATION_SECTION_CODE;
}) => {
  return [
    PROPERTY_INFORMATION_SECTION_CODE.PARCEL_INFO,
    PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS,
  ].includes(code);
};

export const OverviewSectionsTable = ({
  accountSections,
  ruleDefinition,
  onUpdate,
}: {
  accountSections?: NonNullable<
    GetAccountPropertyInformationSectionsQuery["account"]
  >["propertyInformationSections"];
  ruleDefinition: { rules: Array<DfeRule> };
  onUpdate: () => void;
}) => {
  const { account, authorized } = useContext(AuthContext);
  const canEditSection = authorized({
    resource: RESOURCE_NAME.PROPERTY_ATTRIBUTE,
    permission: "update",
  });
  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const [showDeleteOverviewSectionModal] = useDeleteOverviewSectionModal({
    onUpdate,
    ruleDefinition,
  });

  const [updateDisplayOrder, { loading: loadingReorder }] =
    useUpdatePropertyInformationSectionDisplayOrderMutation({
      onCompleted: () => {
        addSuccessToast("Sections reordered successfully");
        onUpdate();
      },
      onError: () => {
        addErrorToast("Failed to reorder sections");
      },
    });

  const renderActionButton = ({
    existingSection,
  }: {
    existingSection: OverviewSectionsTableProps;
  }) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit section",
        disabled: !canEditSection,
        to: {
          pathname: buildLink("editSection", { sectionId: existingSection.id }),
        },
      },
    ];

    if (existingSection.code === PROPERTY_INFORMATION_SECTION_CODE.CUSTOM) {
      actions.push({
        label: "Delete",
        variant: "red",
        onClick: () =>
          showDeleteOverviewSectionModal({ section: existingSection }),
      });
    }

    return <DropdownMenu actions={actions} isTableAction />;
  };

  const columns: Array<ColumnDef<OverviewSectionsTableProps>> = [
    {
      id: "drag-handle",
      cell: ({ row }) => <RowDragHandleCell rowId={row.original.id} />,
      size: 24,
      meta: { className: "drag-handle" },
    },
    {
      id: "name",
      header: "Section name",
      accessorKey: "label",
      enableSorting: false,
      size: 250,
    },
    {
      id: "attributeCount",
      header: "Attributes",
      size: 90,
      enableSorting: false,
      cell: ({ row }) =>
        isParcelInfoOrRepetitiveLoss(row.original)
          ? "N/A"
          : row.original.attributeCount,
      meta: {
        getCellStyles: ({ row }) => {
          return isParcelInfoOrRepetitiveLoss(row.original)
            ? {
                color: colors.contentPrimary.toString(),
              }
            : {};
        },
      },
    },
    {
      id: "lastModified",
      header: "Last modified",
      size: 120,
      enableSorting: false,
      cell: ({ row }) =>
        formatDateString({
          format: "MM/DD/YYYY",
          dateString: row.original.lastModified,
        }),
      meta: {
        getCellStyles: () => ({
          color: colors.contentPrimary.toString(),
        }),
      },
    },
    {
      id: "actions",
      size: 75,
      maxSize: 75,
      enableSorting: false,
      cell: ({ row }) => renderActionButton({ existingSection: row.original }),
    },
  ];

  if (account?.publicPortal.enabled) {
    columns.splice(2, 0, {
      id: "publicVisibility",
      header: "Public",
      size: 60,
      enableSorting: false,
      cell: ({ row }) => (row.original.hiddenFromPublic ? "No" : "Yes"),
    });
  }

  const currentData =
    accountSections?.map(section => {
      return {
        ...section,
        attributeCount: section.accountPropertyAttributes.length,
        lastModified: max([
          section.updatedAt,
          ...section.accountPropertyAttributes.map(
            attribute => attribute.updatedAt
          ),
        ]),
      };
    }) ?? [];

  return (
    <Table<OverviewSectionsTableProps>
      columns={columns}
      currentData={currentData}
      loadingDetails={{
        loading: !accountSections,
        loadingText: "Loading...",
        noDataText: "No Overview Sections Found",
      }}
      tableStyleDetails={{
        hasRowActions: true,
        isCompact: true,
        hasFlushedSides: true,
      }}
      onDragEnd={async newValues => {
        await updateDisplayOrder({
          variables: {
            data: {
              sections: newValues.map((section, index) => ({
                id: section.id,
                displayOrder: index,
              })),
            },
          },
        });
      }}
      loadingDragAction={loadingReorder}
      excludePaginationNav
    />
  );
};
