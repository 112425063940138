import React, {
  Dispatch,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ColumnDef,
  InitialTableState,
  Row,
  SortingState,
  TableState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from "@tanstack/react-table";
import { clamp, isEmpty, isEqual, omit, toArray } from "lodash";
import { useHistory, useLocation } from "react-router";

import {
  DEFAULT_PAGE_SIZE,
  LoadingDetails,
  TableStyleDetails,
  getCellMinWidth,
} from "../Tables/utils";
import { Filter, FilterState, Filters } from "../Filters";
import {
  ManualPaginationConfig,
  QueryDescriptionReducerAction,
  useLegacyTableStateInURL,
} from "../Tables/hooks";
import { URLSortParams } from "../../../utils/sorting";
import { CSS } from "../../../stitches.config";
import { ScrollContainer, ScrollContainerContext } from "./ScrollContainer";
import PaginationSection from "../Tables/PaginationSection";
import { DropdownMenu } from "./DropdownMenu";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../ActionCell";
import { AuthContext } from "../../Authorization/AuthContext";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../../hooks/useTableDisplayConfig";

import {
  ColumnGradient,
  NoDataOverlay,
  StyledTable,
  TableFooter,
  TableHeader,
  LoadingOverlay,
  TABLE_BORDER,
  Resizer,
  CellDiv,
} from "./__styles__/FullWidthTable";
import { sanityCheckColumnSizing, sanityCheckSorting } from "./utils";
import { Filter as NewFilters } from "./TableSettings/Filters";
import { Attribute } from "./types";
import { TableContext } from "./TableContext";
import { TimeoutErrorState } from "./TimeoutErrorState";
import { GraphQLError } from "graphql";
import {
  FilterDescription,
  QueryDescription,
  SyntheticFieldDescription,
} from "common/utils/queryBuilder";
import { ColumnsButton } from "./TableSettings/Columns";
import { SavedView } from "../../../generated/graphql";
import { SavedViewsButton } from "./TableSettings/SavedViews";
import { SaveButton } from "./TableSettings/SavedViews/SaveButton";
import { FlexRow } from "../__styles__/Layout";
import { Icon } from "../Icons/LucideIcons";
import { Button } from "../Button";
import { buildLink } from "common/routing";
import { track } from "../../../utils/tracking";
import { handleKeyDown } from "../../../utils/accessibility";

export interface FullWidthTableProps<T, FS> {
  previousData?: Array<T>;
  currentData: Array<T>;
  columns: Array<ColumnDef<T>>;
  minRows?: number;
  initialState?: InitialTableState & { filters?: FS };
  loadingDetails: LoadingDetails;
  manualPaginationConfig?: ManualPaginationConfig;
  tableStyleDetails?: TableStyleDetails;
  actions?: React.ReactNode;
  defaultSortParams?: URLSortParams;
  prevLocation?: string;
  filterable?: {
    newFilterConfiguration?: Array<Attribute>;
    filterConfigurations?: Array<Filter>;
    search: (params: {
      filters: FS;
      sort: SortingState;
      page: number;
      columns: Array<ColumnDef<T>>;
    }) => void;
  };
  interactiveHeaders?: boolean;
  rowCanExpand?: (row: Row<T>) => boolean;
  renderSubComponent?: (row: Row<T>) => Maybe<JSX.Element>;
  excludePaginationNav?: boolean;
  excludeTableHeader?: boolean;
  setTableStateInURL?: ReturnType<typeof useLegacyTableStateInURL>[1];
  setQueryDescriptionInURL?: (args: any) => void;
  withoutSideNav?: boolean;
  columnSettingProps?: Maybe<{
    columnConfiguration: Attribute[];
    columnDefinitions: ColumnDef<T>[];
  }>;
  timeoutError?: Maybe<GraphQLError>;
  queryDescription?: QueryDescription;
  currentView?: SavedView;
  updateQueryDescription?: Dispatch<QueryDescriptionReducerAction>;
  savedViews?: Array<SavedView>;
}

export const FullWidthTable = <T, FS>({
  previousData,
  currentData,
  columns: initialColumns,
  initialState = {
    pagination: {
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    },
  },
  loadingDetails,
  manualPaginationConfig,
  prevLocation,
  actions,
  filterable,
  interactiveHeaders = true,
  rowCanExpand = () => false,
  excludePaginationNav = false,
  setTableStateInURL,
  updateQueryDescription,
  withoutSideNav = false,
  columnSettingProps,
  timeoutError,
  queryDescription,
  currentView,
  savedViews,
}: FullWidthTableProps<T, FS> & { css?: CSS }) => {
  const history = useHistory();
  const { user, admin } = useContext(AuthContext);
  const { pathname } = useLocation();
  const { loading, loadingText, noDataText } = loadingDetails;
  const [localLoading, setLocalLoading] = useState(loading);
  const [scrollContainerHeight, setScrollContainerHeight] = useState("100%");
  const tableRef = useRef(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [filterState, setFilterState] = useState<FilterState>(
    initialState.filters ?? {}
  );
  const [columnOrder, setColumnOrder] = useState<Array<string>>(() =>
    initialColumns.map(c => c.id!)
  );
  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    if (savedViews) {
      setColumns(initialColumns);
      setColumnOrder(initialColumns.map(c => c.id!));
    }
  }, [currentView]);

  const [addingNewView, setAddingNewView] = useState(false);

  const defaultView = savedViews?.find(view => view.isDefault)!;

  const { id: tableId, name: tableName } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname,
  });

  const {
    getLocalTableState,
    setLocalColumnOrder,
    setLocalColumnSizing,
    setLocalSorting,
  } = useLocalTableDisplayConfig({
    tableId,
    defaultValue: {
      columnOrder: initialColumns.map(column => column.id!),
      columnSizing: {},
      sorting: [],
    },
  });

  const localTableConfig = getLocalTableState();

  const { columnSizing, sorting } = localTableConfig;

  const newColumnSizing = sanityCheckColumnSizing({
    columnSizing,
    columns,
  });

  const newSorting = sanityCheckSorting({ sorting, columns });

  const data = localLoading || loading ? previousData ?? [] : currentData;

  const tableState: Partial<TableState> = {
    columnOrder,
  };

  if (manualPaginationConfig) {
    tableState.pagination = manualPaginationConfig.pagination;
  }

  const defaultColumn = {
    size: 150,
    maxSize: 400,
    minSize: 100,
  };

  const onFilterChange = (filters: Array<FilterDescription>) => {
    table.setPagination(prevPagination => ({
      ...prevPagination,
      pageIndex: 0,
    }));

    updateQueryDescription &&
      updateQueryDescription({ type: "updateFilters", data: filters });
  };

  const onColumnChange = (columnId: string, action: "add" | "remove") => {
    let newList = [...columns];

    if (action === "add") {
      const definition = columnSettingProps?.columnDefinitions.find(
        c => c.id === columnId
      );

      if (definition) {
        newList.splice(-1, 0, definition);
        setColumns(newList);
        setColumnOrder(newList.map(c => c.id!));
      }
    } else {
      newList = columns.filter(column => column.id !== columnId);
      setColumns(newList);
      setColumnOrder(newList.map(c => c.id!));
    }

    if (updateQueryDescription) {
      updateQueryDescription({
        type: "updateFields",
        data: newList.map(c => c.id!),
      });
    }
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnSizing: newColumnSizing ?? columnSizing,
      sorting: newSorting ?? sorting,
      ...omit(initialState, "filters"),
      ...(excludePaginationNav
        ? {
            pagination: {
              pageIndex: 0,
              pageSize:
                DEFAULT_PAGE_SIZE > data.length
                  ? DEFAULT_PAGE_SIZE
                  : data.length,
            },
          }
        : {}),
    },
    state: tableState,
    //when using a query description we already return sorted data so we don't want to use the sorting from the table state
    manualSorting: !!queryDescription,
    getSortedRowModel: getSortedRowModel(),
    ...(manualPaginationConfig
      ? omit(manualPaginationConfig, "pagination")
      : {
          manualPagination: false,
          getPaginationRowModel: getPaginationRowModel(),
        }),
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex: false,
    enableExpanding: true,
    getRowCanExpand: rowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: "onChange",
    defaultColumn,
  });

  const innerTableState = table.getState();

  const search = () =>
    filterable?.search({
      filters: filterState as FS,
      sort: innerTableState.sorting,
      page: innerTableState.pagination.pageIndex + 1,
      columns,
    });

  useEffect(() => {
    if (setTableStateInURL) {
      setTableStateInURL({
        filters: filterState,
        pagination: innerTableState.pagination,
        sorting: innerTableState.sorting,
        prevLocation,
      });

      search();
    }
  }, [
    filterState,
    innerTableState.pagination,
    innerTableState.sorting,
    columns,
  ]);

  useEffect(() => {
    if (!setTableStateInURL) {
      search();
    }
  }, [queryDescription, innerTableState.pagination]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLocalLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, [loading]);

  useEffect(() => {
    if (!scrollableRef.current) return;

    const siblings = toArray(
      scrollableRef.current.parentElement?.children
    ).filter(child => {
      const ignoredClassNames = ["scroll-wrapper", "loading-overlay"];
      return !toArray(child.classList).some(className =>
        ignoredClassNames.includes(className)
      );
    });

    const combinedHeightOfSiblings = siblings.reduce((totalHeight, element) => {
      return totalHeight + element.clientHeight;
    }, 0);

    setScrollContainerHeight(`calc(100vh - ${combinedHeightOfSiblings}px)`);
  }, [scrollableRef.current?.parentElement?.children, loading]);

  useEffect(() => {
    setLocalColumnSizing(innerTableState.columnSizing);
  }, [JSON.stringify(innerTableState.columnSizing)]);

  useEffect(() => {
    table.setPagination(prevPagination => ({
      ...prevPagination,
      pageIndex: 0,
    }));
    setLocalSorting(innerTableState.sorting);

    updateQueryDescription &&
      updateQueryDescription({
        type: "updateOrder",
        data: innerTableState.sorting,
      });
  }, [innerTableState.sorting]);

  const legacyFilterChange = (filters: Record<string, unknown>) => {
    table.setPagination(prevPagination => ({
      ...prevPagination,
      pageIndex: 0,
    }));

    setFilterState(filters);
  };

  const Table = () => {
    const { scrolledToLeft, scrolledToRight, handleScroll } = useContext(
      ScrollContainerContext
    );

    const showBottomBorder = data.length < DEFAULT_PAGE_SIZE;

    const Gradient = ({ index }: { index: number }) => {
      const getGradientDirection = (index: number) => {
        if (index === 0 && !scrolledToLeft) {
          return "leftToRight";
        } else if (index === columns.length - 1 && !scrolledToRight) {
          return "rightToLeft";
        } else {
          return "none";
        }
      };

      return (
        <ColumnGradient
          direction={getGradientDirection(index)}
          className="gradient"
        />
      );
    };

    useEffect(() => {
      setLocalColumnOrder(columnOrder);
    }, [columnOrder]);

    const columnSizeVars = React.useMemo(() => {
      const headers = table.getFlatHeaders();
      const colSizes: { [key: string]: number | string } = {};

      for (let i = 0; i < headers.length; i++) {
        const header = headers[i]!;

        //We keep the last column at 100%, since it needs to take up all remaining available space in table width
        const isLastColumn = i === headers.length - 1;
        if (isLastColumn) {
          colSizes[header.id] = "100%";
        } else {
          const savedSize = columnSizing[header.id];
          const defaultSize = savedSize
            ? savedSize
            : header.column.columnDef.size ?? defaultColumn.size;
          const newSize =
            innerTableState.columnSizing[header.id] ?? defaultSize;

          const min = header.column.columnDef.minSize ?? defaultColumn.minSize;
          const max = header.column.columnDef.maxSize ?? defaultColumn.maxSize;

          const size = clamp(newSize, min, max);

          colSizes[header.id] = size;
        }
      }

      return colSizes;
    }, [innerTableState.columnSizingInfo, innerTableState.columnSizing]);

    useLayoutEffect(() => {
      handleScroll();
    }, [columnSizeVars]);

    const TableBody = ({ table }: { table: Table<T> }) => {
      return (
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id} data-testid="row">
                {row.getVisibleCells().map((cell, index) => {
                  const value = cell.getValue();
                  const hoverText = typeof value === "string" ? value : "";
                  return (
                    <td
                      key={cell.id}
                      style={{
                        width: columnSizeVars[cell.column.id],
                        minWidth: getCellMinWidth<T>(cell),
                      }}
                    >
                      <CellDiv
                        isActionColumn={
                          cell.column.id === ACTION_COLUMN_DEF_CONSTANTS.id
                        }
                        title={hoverText}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </CellDiv>
                      <Gradient index={index} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      );
    };

    const MemoizedTableBody = React.memo<typeof TableBody>(
      TableBody,
      (prev, next) => prev.table.options.data === next.table.options.data
    );

    return (
      <StyledTable
        ref={tableRef}
        style={{
          borderBottom: showBottomBorder ? TABLE_BORDER : "unset",
          width: table.getTotalSize(),
          ...columnSizeVars,
        }}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index, array) => {
                const isLastColumn = index === array.length - 1;
                const [open, setOpen] = useState(false);
                const orderableClass =
                  interactiveHeaders && !isLastColumn ? "order-pointer" : "";
                const sortableClass = header.column.getCanSort()
                  ? "sort-pointer"
                  : "";
                const sortDirectionClass =
                  {
                    asc: "sort-asc",
                    desc: "sort-desc",
                  }[header.column.getIsSorted() as string] ?? "";

                const { tooltip, className: additionalClasses = "" } =
                  header.column.columnDef.meta ?? {};

                const columnHeader = header.column.columnDef.header;
                const hoverText = tooltip
                  ? tooltip
                  : typeof columnHeader === "string"
                  ? columnHeader
                  : "";

                return (
                  <th
                    key={header.id}
                    role="columnheader"
                    className={`${orderableClass} ${sortableClass} ${sortDirectionClass} ${additionalClasses}`}
                    style={{
                      width: columnSizeVars[header.id],
                      minWidth: getCellMinWidth<T>(header),
                    }}
                    onKeyPress={event => {
                      if (event.key === "Enter" || event.key === " ") {
                        interactiveHeaders && setOpen(!open);
                      }
                    }}
                    onClick={() => {
                      interactiveHeaders && setOpen(!open);
                    }}
                    tabIndex={
                      header.column.getCanSort() || interactiveHeaders
                        ? 0
                        : undefined
                    }
                    data-testid={`header-${header.id}`}
                    data-tooltip={hoverText}
                  >
                    <div>
                      {tooltip && (
                        <Icon
                          iconName="info"
                          size={12}
                          color={"contentSecondary"}
                          className="info-icon"
                          style={{
                            marginRight: "8px",
                            top: "1px",
                            position: "relative",
                          }}
                        />
                      )}
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                    {/* we don't include the resizer on the last column since it needs to take up the rest of the available table space */}
                    {!isLastColumn && (
                      <Resizer
                        key={header.id}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        onDoubleClick={() => header.column.resetSize()}
                        className={`resizer`}
                        isResizing={header.column.getIsResizing()}
                        data-testid={`resizer-${header.id}`}
                      />
                    )}
                    <Gradient index={index} />
                    <DropdownMenu
                      header={header}
                      setColumnOrder={setColumnOrder}
                      open={open && !isLastColumn}
                      setOpen={setOpen}
                      removeColumn={id => onColumnChange(id, "remove")}
                    />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <MemoizedTableBody table={table} />
      </StyledTable>
    );
  };

  const onAddNewView = () => {
    if (updateQueryDescription && currentView) {
      const firstField = defaultView.query
        .fields[0] as SyntheticFieldDescription;

      updateQueryDescription({
        type: "initializeQueryDescription",
        data: { ...defaultView!.query, fields: [firstField] },
      });

      setColumns(initialColumns);
      setColumnOrder(initialColumns.map(c => c.id!));

      setAddingNewView(true);
    }
  };

  const onCancel = () => {
    if (updateQueryDescription && currentView) {
      updateQueryDescription({
        type: "initializeQueryDescription",
        data: currentView.query,
      });

      setColumns(initialColumns);
      setColumnOrder(initialColumns.map(c => c.id!));
      setAddingNewView(false);
    }
  };

  const hasViewUpdates = !isEqual(queryDescription, currentView?.query);

  return (
    <TableContext.Provider
      value={{
        name: tableName,
        currentQuery: queryDescription,
        currentView,
        defaultView,
      }}
    >
      <TableHeader>
        <FlexRow style={{ gap: "8px" }}>
          {savedViews && (
            <SavedViewsButton
              savedViews={savedViews}
              addingNewView={addingNewView}
              onAddNewView={onAddNewView}
            />
          )}
          {filterable &&
            (filterable.newFilterConfiguration ? (
              <NewFilters
                config={filterable.newFilterConfiguration}
                onFilterChange={onFilterChange}
                addingNewView={addingNewView}
              />
            ) : (
              <Filters
                filterConfigurations={filterable.filterConfigurations!}
                filterValues={filterState}
                onFilterChange={legacyFilterChange}
                handleKeyDown={handleKeyDown}
              />
            ))}
          {columnSettingProps && (
            <ColumnsButton
              currentColumns={columns}
              columnConfig={columnSettingProps.columnConfiguration}
              hideColumn={id => onColumnChange(id, "remove")}
              addColumn={id => onColumnChange(id, "add")}
              addingNewView={addingNewView}
            />
          )}
        </FlexRow>
        <FlexRow style={{ gap: "8px" }}>
          {currentView && !hasViewUpdates && (
            <Button
              size="small"
              styleVariant="secondary"
              leftIconName="globe-2"
              onClick={() => {
                track("Clicked View on map", { savedViewId: currentView.id });
                history.push({
                  state: { savedViewId: currentView.id },
                  pathname: buildLink("map"),
                });
              }}
            >
              View on map
            </Button>
          )}
          {!addingNewView && actions}
          {hasViewUpdates && (
            <SaveButton
              addingNewView={addingNewView}
              onSave={() => {
                setAddingNewView(false);
              }}
              onCancel={onCancel}
            />
          )}
        </FlexRow>
      </TableHeader>
      <ScrollContainer
        scrollableRef={scrollableRef}
        height={scrollContainerHeight}
        loading={localLoading || loading}
        withoutSideNav={withoutSideNav}
      >
        <Table />
        {!localLoading && !loading && !timeoutError && isEmpty(currentData) && (
          <NoDataOverlay>{noDataText}</NoDataOverlay>
        )}
        {(localLoading || loading) && (
          <LoadingOverlay className="loading-overlay">
            {loadingText}
          </LoadingOverlay>
        )}
        {timeoutError && (
          <TimeoutErrorState
            refetch={() =>
              filterable?.search({
                filters: filterState as FS,
                sort: innerTableState.sorting,
                page: innerTableState.pagination.pageIndex + 1,
                columns,
              })
            }
          />
        )}

        <TableFooter>
          {!loading && !excludePaginationNav && (
            <PaginationSection
              paginationDetails={{
                pageCount: table.getPageCount(),
                pagination: innerTableState.pagination,
                setPagination: table.setPagination,
              }}
              previousPage={table.previousPage}
              nextPage={table.nextPage}
              canGetPreviousPage={table.getCanPreviousPage}
              canGetNextPage={table.getCanNextPage}
            />
          )}
        </TableFooter>
      </ScrollContainer>
    </TableContext.Provider>
  );
};
