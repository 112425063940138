import React from "react";
import { withStopPropagation } from "../utils";
import { BuilderFieldEditButtons } from "../__styles__/SubmissionsBuilder";
import { Button } from "../../../../../Common/Button";
import { Icon } from "../../../../../Common/Icons/LucideIcons";

export interface FieldActionsProps {
  canMoveDown: boolean;
  canMoveUp: boolean;
  canDelete: boolean;
  isSelected: boolean;
  deleteField: () => void;
  duplicateField: () => void;
  moveFieldDown: () => void;
  moveFieldUp: () => void;
  selectField: () => void;
  isGroup?: boolean;
}

const FieldActions = ({
  canMoveUp,
  canMoveDown,
  canDelete,
  isSelected,
  moveFieldUp,
  moveFieldDown,
  duplicateField,
  deleteField,
  isGroup,
}: FieldActionsProps) => {
  const labelTarget = isGroup ? "group" : "field";

  return (
    <footer>
      <BuilderFieldEditButtons>
        {isSelected}
        {canMoveUp && (
          <li>
            <Button
              styleVariant={"ghost"}
              onClick={withStopPropagation(moveFieldUp)}
              aria-label={`Move ${labelTarget} up`}
            >
              <Icon color="contentSecondary" iconName="arrow-up" size={16} />
            </Button>
          </li>
        )}
        {canMoveDown && (
          <li>
            <Button
              styleVariant={"ghost"}
              onClick={withStopPropagation(moveFieldDown)}
              aria-label={`Move ${labelTarget} down`}
            >
              <Icon color="contentSecondary" iconName="arrow-down" size={16} />
            </Button>
          </li>
        )}
        <li>
          <Button
            styleVariant={"ghost"}
            onClick={withStopPropagation(duplicateField)}
            aria-label={`Duplicate ${labelTarget}`}
          >
            <Icon color="contentSecondary" iconName="copy" size={16} />
          </Button>
        </li>
        {canDelete && (
          <li>
            <Button
              styleVariant={"ghost"}
              onClick={withStopPropagation(deleteField)}
              aria-label={`Delete ${labelTarget}`}
            >
              <Icon color="contentSecondary" iconName="x" size={16} />
            </Button>
          </li>
        )}
      </BuilderFieldEditButtons>
    </footer>
  );
};

export default FieldActions;
