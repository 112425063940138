import { buildLink, RouteNames } from "common/routing";
import { LayerContext, SavedView } from "./layers";
import { TABLE_TYPE } from "common/constants";
import { useContext } from "react";
import { SAVED_VIEW_MIN_ZOOM } from "./layers/savedViews";
import { BottomBanner } from "../Common/BottomBanner";
import React from "react";
import { FlexRow } from "../Common/__styles__/Layout";
import { colors, spacing } from "../../stitches.config";
import { Button } from "../Common/Button";
import { useHistory } from "react-router";
import { StyledLink } from "../Common/__styles__/Typography";
import { track } from "../../utils/tracking";
import { generateDisplayName } from "common-client/utils/mapLayers";

const TABLE_TYPE_TO_ROUTE: Record<TABLE_TYPE, RouteNames> = {
  [TABLE_TYPE.PROPERTIES]: "properties",
  [TABLE_TYPE.PERMITS]: "submissions",
  [TABLE_TYPE.INSPECTIONS]: "inspections",
  [TABLE_TYPE.FILES]: "documentUploads",
};

export const SavedViewBanner = ({
  visibleSavedView,
  zoom,
}: {
  visibleSavedView?: Maybe<SavedView>;
  zoom: number;
}) => {
  const { toggleLayer } = useContext(LayerContext);
  const history = useHistory();

  if (!visibleSavedView) {
    return null;
  }

  const link = buildLink(
    TABLE_TYPE_TO_ROUTE[visibleSavedView.tableType],
    {},
    { view: visibleSavedView.id }
  );

  const onClose = () => {
    toggleLayer({
      group: "savedViews",
      id: visibleSavedView.id,
      isVisible: false,
    });
  };

  const subtitle =
    zoom < SAVED_VIEW_MIN_ZOOM ? "Zoom in to view data." : undefined;

  return (
    <BottomBanner
      title={generateDisplayName(visibleSavedView)}
      subtitle={subtitle}
      onClose={onClose}
      style="table"
    >
      <FlexRow style={{ gap: spacing.m.value, alignItems: "center" }}>
        <Button
          styleVariant="outlineDark"
          size="small"
          leftIconName="table-properties"
          onClick={() => {
            track("Clicked Back to table", {
              savedViewId: visibleSavedView.id,
            });
            history.push(link);
          }}
        >
          Back to table
        </Button>

        <StyledLink
          style={{
            color: colors.contentInfoDark.toString(),
            cursor: "pointer",
          }}
          onClick={onClose}
          as="div"
        >
          Close view
        </StyledLink>
      </FlexRow>
    </BottomBanner>
  );
};
