import React, { useContext, useMemo } from "react";
import { sortBy } from "lodash";

import NoticeBoard from "../../../Common/NoticeBoard";
import ValidationError from "./ValidationError";
import { IssueBanner } from "../../../Issues";
import MobileDocumentLink from "../../MobileDocumentLink";
import { pipLink } from "common/routing";
import {
  CertificateUploadStatus,
  ErrorCode,
  ErrorDetectionEligibilityStatus,
  FIRMJurisdiction,
  GetDocumentUploadQuery,
} from "../../../../generated/graphql";
import Icon, { Icons } from "../../../Common/Icons";
import { Icon as LucideIcon } from "../../../Common/Icons/LucideIcons";
import { DocumentUploadContext, TAB_NAME } from "../../Tabs";
import {
  Checklist,
  ErrorContainer,
  Heading,
  OverlapWarning,
  ValidationErrorsContainer,
} from "./__styles__";
import { CertificateType } from "../../__queries__";
import {
  CERTIFICATE_VIEW_FIRM_WARNINGS,
  floodzoneOrCoastalAIntersectionsPerFirm,
  getWarningsForFirms,
  propertyInMultiplePanels,
} from "common-client/utils/firmInfoWarnings";
import { editableInformationForFIRMs } from "common-client/utils/firms";
import { fieldsForCertificate } from "../../Forms/ElevationCertificates/ExtractedFields";
import { CertificateFormFieldDottableKeys } from "../../Forms/ElevationCertificates/CertificatesInputs";
import { AuthContext } from "../../../Authorization/AuthContext";
import { StyledReactRouterLink } from "../../../Common/__styles__/Typography";
import { FirmWarningsBanner } from "../../../AddressPanel/PropertyOverview/FIRMInfo/FirmWarningsBanner";

type FIRMs = NonNullable<
  NonNullable<GetDocumentUploadQuery["documentUpload"]>["property"]
>["firms"];
type ValidationErrors = CertificateType["validationErrors"];
type ValidationError = NonNullable<
  NonNullable<ValidationErrors>["data"]
>[number];

const PANELS_OVERLAP_ERROR_CODES = [
  ErrorCode.FIRM_PANEL_EFFECTIVE_DATE_MISMATCH,
  ErrorCode.PANEL_NUMBER_MISMATCH,
  ErrorCode.SUFFIX_MISMATCH,
];

const REGULATORY_ZONES_OVERLAP_ERROR_CODES = [
  ErrorCode.REGULATORY_LFE_BELOW_DFE,
  ErrorCode.REGULATORY_LFEHAG_BELOW_DFE,
  ErrorCode.REGULATORY_MACHINERY_BELOW_AODFE,
  ErrorCode.REGULATORY_MACHINERY_BELOW_DFE,
];

const NFIP_ZONES_OVERLAP_ERROR_CODES = [
  ErrorCode.BFE_FIRM_MISMATCH,
  ErrorCode.C2C_BLANK_OR_NA,
  ErrorCode.FLOODZONE_FIRM_MISMATCH,
  ErrorCode.NFIP_LFE_BELOW_BFE,
  ErrorCode.NFIP_LFEHAG_BELOW_BFE,
  ErrorCode.NFIP_MACHINERY_BELOW_AOBFE,
  ErrorCode.NFIP_MACHINERY_BELOW_BFE,
];

const getFirmIdForError = ({
  error,
  firms,
}: {
  error: ValidationError;
  firms: FIRMs;
}) => {
  let jurisdiction: Maybe<FIRMJurisdiction> = null;

  if (NFIP_ZONES_OVERLAP_ERROR_CODES.includes(error.errorCode)) {
    jurisdiction = FIRMJurisdiction.NFIP;
  } else if (REGULATORY_ZONES_OVERLAP_ERROR_CODES.includes(error.errorCode)) {
    jurisdiction = FIRMJurisdiction.REGULATORY;
  }

  if (jurisdiction) {
    return firms.find(firm => firm.jurisdictions.includes(jurisdiction!))?.id;
  } else {
    return null;
  }
};

const ValidationErrors = ({ setTab }: { setTab: (tab: TAB_NAME) => void }) => {
  const { documentUpload } = useContext(DocumentUploadContext);

  const { errorDetectionEligibility, validationErrors } =
    documentUpload.elevationCertificate!;
  const errors = validationErrors?.data || [];
  const hasErrors = errors.length > 0;
  const firmEditUrl = pipLink({
    propertyId: documentUpload.property?.id,
    address: documentUpload.property?.fullAddress ?? "",
    lng: documentUpload.property?.longitude.toString() ?? "",
    lat: documentUpload.property?.latitude.toString() ?? "",
  });

  let body;

  const firms =
    documentUpload.property?.firms.filter(firm => firm.useForRegulation) ?? [];

  const intersections = useMemo(() => {
    return editableInformationForFIRMs(firms);
  }, []);

  const isEligible =
    errorDetectionEligibility.status ===
    ErrorDetectionEligibilityStatus.ELIGIBLE;

  if (!hasErrors) {
    const icon = isEligible ? (
      <Icon icon={Icons.NO_RESULTS} />
    ) : (
      <Icon icon={Icons.DO_NOT_ENTER} />
    );
    const noticeBoardProps = { icon, ...errorDetectionEligibility };

    body = <NoticeBoard {...noticeBoardProps} />;
  } else {
    const floodzoneIntersectionsPerFirm =
      floodzoneOrCoastalAIntersectionsPerFirm(intersections);

    const multiplePanels = propertyInMultiplePanels(intersections);

    // We want errors to be orderd the same way that Extracted Fields are
    const sortedFields = fieldsForCertificate(
      documentUpload.elevationCertificate!
    ).map(m => m.name);

    const sortedErrors = sortBy(
      errors,
      error => {
        const valueAttr = error.valueAttr[0]!;
        let index = sortedFields.indexOf(
          valueAttr as CertificateFormFieldDottableKeys
        );
        // We have a bug where for fields that use `buildNumericNAValidation` validator in the backend,
        // it returns the name of the flat field instead of the dottable. Those return -1 because they aren't
        // in sortedFields. All those fields happen to be from `buildingElevationInfo`, so we can just manually tack it on
        if (index === -1) {
          index = sortedFields.indexOf(
            `buildingElevationInfo.${valueAttr}.value` as CertificateFormFieldDottableKeys
          );
        }
        return index;
      },
      error => {
        return error.type === "error" ? 0 : 1;
      }
    );

    body = sortedErrors.map(error => {
      const { note, errorCode, id } = error;
      const errorFirmId = getFirmIdForError({
        error,
        firms: documentUpload.property?.firms ?? [],
      });

      const zonesIntersect = errorFirmId
        ? floodzoneIntersectionsPerFirm[errorFirmId]
        : false;
      const panelsIntersect =
        multiplePanels && PANELS_OVERLAP_ERROR_CODES.includes(errorCode);

      const showNote = (zonesIntersect || panelsIntersect) && note;

      return (
        <ValidationError
          key={id}
          error={{ ...error, note: showNote ? note : null }}
          label={error.field}
          value={error.value}
          setTab={setTab}
        />
      );
    });
  }

  const firmsWithWarnings = getWarningsForFirms({
    firms,
    editable: intersections,
    showCoastalA: true,
  });

  return (
    <ValidationErrorsContainer data-testid="validation-errors">
      {body}

      {isEligible && (
        <OverlapWarning>
          <FirmWarningsBanner
            firms={firmsWithWarnings}
            warningTextConfig={CERTIFICATE_VIEW_FIRM_WARNINGS}
            propertyId={documentUpload.property?.id}
            compactList={true}
            additionalReplacements={{
              "{PROPERTY_LINK}": (
                <StyledReactRouterLink
                  to={firmEditUrl}
                  style={{
                    color: "inherit",
                    textDecoration: "underline",
                    fontWeight: "inherit",
                  }}
                >
                  View FIRM Info here.
                </StyledReactRouterLink>
              ),
            }}
          />
        </OverlapWarning>
      )}
    </ValidationErrorsContainer>
  );
};

const ProcessingDisplay = () => {
  const icon = <LucideIcon iconName="triangle-alert" color="contentPrimary" />;

  return (
    <ValidationErrorsContainer>
      <NoticeBoard
        icon={icon}
        heading="We're processing this Elevation Certificate"
        note="Please check back later for extracted fields and error detection"
      />
    </ValidationErrorsContainer>
  );
};

export type ErrorsProps = {
  fileUrl: string;
  setTab: (tab: TAB_NAME) => void;
};

export default ({ fileUrl, setTab }: ErrorsProps) => {
  const { documentUpload } = React.useContext(DocumentUploadContext);
  const { isGuest } = React.useContext(AuthContext);
  const issueProps = {
    certificateId: documentUpload.elevationCertificate?.id,
    address: documentUpload.property?.fullAddress ?? "",
    coordinates: [
      documentUpload.property?.longitude,
      documentUpload.property?.latitude,
    ] as Coordinates,
  };
  return (
    <ErrorContainer data-testid="error-container">
      {!!documentUpload.elevationCertificate && (
        <ValidationErrors setTab={setTab} />
      )}
      {!documentUpload.elevationCertificate && <ProcessingDisplay />}

      <Checklist data-testid="error-issues-guide">
        <Heading>
          Our Issues feature does not replace a file review process from a
          Certified Floodplain Manager. Please read through our{" "}
          <a
            href="https://forerunner.helpscoutdocs.com/article/35-issues-guide"
            target="_blank"
          >
            Issues Guide
          </a>{" "}
          to ensure that you understand the full capabilities of the feature.
          Here are a few items that need to be checked for manually on each
          Elevation Certificate:
        </Heading>
        <ul>
          <li>
            <span>
              Accuracy and formatting of Latitude & Longitude coordinates
            </span>
          </li>
          <li>
            <span>Inclusion of at least 2 photographs</span>
          </li>
          <li>
            <span>Correct Diagram Number selection</span>
          </li>
          <li>
            <span>
              Accuracy of CBRS & OPA determination on ECs with an expiration
              year prior to 2026
            </span>
          </li>
          <li>
            <span>Accuracy of FIRM Index Date</span>
          </li>
          <li>
            <span>
              Presence of surveyor, engineer, or architect stamp & signature
            </span>
          </li>
          <li>
            <span>Accuracy of Section F and G</span>
          </li>
          <li>
            <span>Presence of Section F</span>
          </li>
          <li>
            <span>
              Presence of Section E and G on ECs with an expiration year prior
              to 2026
            </span>
          </li>
          <li>
            <span>
              Attachment of Flood Openings Certification, where applicable
            </span>
          </li>
          <li>
            <span>Attachment of V Zone Certification, where applicable</span>
          </li>
          <li>
            <span>Amendments made using a Memo of Correction</span>
          </li>
          <li>
            <span>Presence of breakaway walls</span>
          </li>
          <li>
            <span>
              Other regulatory issues that are specific to your jurisdiction
            </span>
          </li>
        </ul>
      </Checklist>

      <MobileDocumentLink fileUrl={fileUrl} documentUpload={documentUpload} />

      {!(
        isGuest ||
        documentUpload.certificateUpload?.status ===
          CertificateUploadStatus.PROCESSING
      ) && <IssueBanner {...issueProps} />}
    </ErrorContainer>
  );
};
