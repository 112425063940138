import React, { useContext, useState } from "react";
import { Button } from "./Button";
import { useModal } from "react-modal-hook";
import Modal from "./Modal";
import { AuthContext } from "../Authorization/AuthContext";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { CurrentUserQuery, SubmissionCategory } from "../../generated/graphql";
import { buildLink } from "common/routing";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "./__styles__/Modal";
import { Radio as ReactHookFormRadio } from "../Inputs/react-hook-form";
import { formatCoordinates } from "common/utils/coordinates";
import { capitalize, uniq } from "lodash";
import { Radio } from "../Inputs";
import { arrayHasExactlyOneItem } from "common/utils/arrays";

const SUBMISSION_CATEGORY_TO_DISPLAY: Record<SubmissionCategory, string> = {
  [SubmissionCategory.INSPECTIONS]: "inspection",
  [SubmissionCategory.PERMITTING]: "permit",
  [SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]: "SI/SD",
  [SubmissionCategory.GRANTS]: "grant",
  [SubmissionCategory.PAYMENTS]: "payment",
};

export const CreateSubmissionButton = ({
  disabled,
  submissionTypeFilter,
  buttonText = "Add new",
  hideIfNoOptions = false,
  ...props
}: Omit<SelectSubmissionTypeProps, "onCancel" | "submissionTypes"> & {
  disabled: boolean;
  submissionTypeFilter: (
    submissionType: NonNullable<
      CurrentUserQuery["currentUser"]
    >["account"]["submissionTypes"][number]
  ) => boolean;
}) => {
  const { account } = useContext(AuthContext);
  const history = useHistory();
  const submissionTypes: Array<{
    id: string;
    name: string;
    category: SubmissionCategory;
  }> = account?.submissionTypes.filter(submissionTypeFilter) ?? [];

  const [showSelectInspectionModal] = useSelectSubmissionTypeModal({
    ...props,
    submissionTypes,
  });

  const goToCreateSubmission = () => {
    history.push({
      pathname: buildLink("createSubmission", {
        submissionTypeId: submissionTypes[0]!.id,
        propertyId: props.propertyId,
      }),
      state: {
        prevLocation: history.location.pathname,
        search: location.search,
      },
    });
  };

  if (hideIfNoOptions && submissionTypes.length === 0) {
    return null;
  }

  return (
    <Button
      onClick={
        submissionTypes.length === 1
          ? goToCreateSubmission
          : showSelectInspectionModal
      }
      size="small"
      disabled={disabled || submissionTypes.length === 0}
      styleVariant="primary"
      leftIconName="plus"
    >
      {buttonText}
    </Button>
  );
};

export type SelectSubmissionTypeProps = {
  address?: Maybe<string>;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
  onCancel: () => void;
  propertyId: string;
  submissionTypes: Array<{
    id: string;
    name: string;
    category: SubmissionCategory;
  }>;
  buttonText?: string;
  hideIfNoOptions?: boolean;
};

const useSelectSubmissionTypeModal = (
  props: Omit<SelectSubmissionTypeProps, "onCancel">
) => {
  const [showModal, hideModal] = useModal(() => (
    <Modal onRequestClose={hideModal}>
      <SelectSubmissionType onCancel={hideModal} {...props} />
    </Modal>
  ));

  return [showModal, hideModal];
};

type SelectSubmissionTypeFormStructure = {
  submissionTypeId: string;
};

const SelectSubmissionType = ({
  address,
  latitude,
  longitude,
  onCancel,
  propertyId,
  submissionTypes,
}: SelectSubmissionTypeProps) => {
  const history = useHistory();
  const categories = uniq(submissionTypes.map(f => f.category));
  const [submissionCategory, setSubmissionCategory] = useState<
    SubmissionCategory | ""
  >(arrayHasExactlyOneItem(categories) ? categories[0] : "");

  const [submissionCategorySelected, setSubmissionCategorySelected] =
    useState<boolean>(!!submissionCategory);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<SelectSubmissionTypeFormStructure>();

  const onCreate = (formData: SelectSubmissionTypeFormStructure) => {
    history.push({
      pathname: buildLink("createSubmission", {
        submissionTypeId: formData.submissionTypeId,
        propertyId,
      }),
      state: {
        prevLocation: history.location.pathname,
        search: location.search,
      },
    });
  };

  const submissionText =
    submissionCategory && submissionCategorySelected
      ? SUBMISSION_CATEGORY_TO_DISPLAY[submissionCategory]
      : "record";

  let subheader = `Select the ${submissionText} that you would like to create`;
  if (address) {
    subheader += ` for ${address}`;
  } else if (!!latitude && !!longitude) {
    subheader += ` for ${formatCoordinates({
      latitude,
      longitude,
    })}`;
  }

  if (categories.length > 1 && !submissionCategorySelected) {
    return (
      <Container>
        <HeaderSection>
          <h1>Select record category</h1>
          <h2>{subheader}</h2>
        </HeaderSection>
        <ContentSection>
          <Radio
            name="submissionCategory"
            value={submissionCategory}
            options={categories.map(category => ({
              value: category,
              text:
                category ===
                SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
                  ? SUBMISSION_CATEGORY_TO_DISPLAY[category]
                  : capitalize(SUBMISSION_CATEGORY_TO_DISPLAY[category]),
            }))}
            onChange={value =>
              setSubmissionCategory(value as SubmissionCategory)
            }
          />
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={onCancel} size="medium">
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              onClick={() => setSubmissionCategorySelected(true)}
              disabled={!submissionCategory}
              size="medium"
            >
              Next
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderSection>
        <h1>Select {submissionText} type</h1>
        <h2>{subheader}</h2>
      </HeaderSection>
      <ContentSection>
        <ReactHookFormRadio
          control={control}
          name="submissionTypeId"
          options={submissionTypes
            .filter(
              submissionType => submissionType.category === submissionCategory
            )
            .map(f => ({ value: f.id, text: f.name }))}
          required={true}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            onClick={handleSubmit(onCreate)}
            disabled={!isValid}
            size="medium"
          >
            Create
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
