import React, {
  useState,
  useEffect,
  useRef,
  ComponentProps,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import {
  Category,
  CategoryFieldValues,
  Field,
  SearchFormProps,
  buildCategoriesForAccount,
} from "common-client/utils/Search";

import { useGetSearchResultsLazyQuery } from "../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import type { Point } from "../Maps/LayeredMap/types";
import { SearchField } from "./SearchField";
import { ResultsDropdown } from "./ResultsDropdown";
import { CategoryDropdown } from "./CategoryDropdown";
import { InternalMapContext } from "../Maps/InternalMapContextProvider";

import {
  CategoryWrapper,
  IconWrapper,
  InputWrapper,
  Wrapper,
  Label,
} from "./__styles__/Search";
import { AuthContext } from "../Authorization/AuthContext";
export interface SearchResultProps {
  point: Point;
  address: string;
  propertyId?: Maybe<string>;
  geocacheParcelId?: Maybe<string>;
  streetAddress?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
}

export type Status =
  | "idle"
  | "loading"
  | "search_complete"
  | "selecting_category";

export const Search = ({
  handleResultClick,
  originalValue,
  accountId,
  addressOnly = false,
}: {
  handleResultClick: ComponentProps<
    typeof ResultsDropdown
  >["handleResultClick"];
  originalValue?: string;
  accountId?: Maybe<string>;
  addressOnly?: boolean;
}) => {
  const { setResetSearchCategory } = useContext(InternalMapContext);
  const { account } = useContext(AuthContext);

  const [status, setRawStatus] = useState<Status>("idle");
  const [previousStatus, setPreviousStatus] = useState<Status>(status);
  const [focused, setFocused] = useState<boolean>(false);

  const setStatus = (newStatus: Status) => {
    setPreviousStatus(status);
    setRawStatus(newStatus);
  };

  const { categories, defaultCategory } = buildCategoriesForAccount(account);

  const [selectedCategory, setSelectedCategory] =
    useState<Category>(defaultCategory);

  useEffect(() => {
    if (setResetSearchCategory) {
      setResetSearchCategory(() => {
        setSelectedCategory(defaultCategory);
        reset();
        onClear();
      });
    }
  }, []);

  const multipleFieldSearch = selectedCategory.fields.length > 1;
  const wrapperRef = useRef(null as null | HTMLDivElement);
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => {
      setFocused(false);
      if (status === "selecting_category") setStatus(previousStatus);
    },
    dependencies: [status, previousStatus],
  });

  const { getValues, setValue, control, resetField, reset } =
    useForm<SearchFormProps>({
      defaultValues: {
        address: originalValue,
      },
    });

  const [getSearchResults, { data, loading }] = useGetSearchResultsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      setStatus("search_complete");
    },
  });

  useEffect(() => {
    setValue("address", originalValue);
  }, [originalValue]);

  useEffect(() => {
    if (loading) {
      setStatus("loading");
    }
  }, [loading]);

  const handleOnChange = () => {
    const fields = getValues();

    if (
      Object.entries(fields).find(([key, value]) => {
        const selectedField = selectedCategory!.fields.find(
          field => field.value === key
        ) as Field | undefined;
        return selectedField
          ? value && value.length >= selectedField.minChars
          : false;
      })
    ) {
      const variables = {
        ...fields,
        category: selectedCategory.value,
        accountId,
      };

      void getSearchResults({
        variables,
      });
    } else {
      setStatus("idle");
    }
  };

  const onClear = () => {
    const values = Object.values(getValues());

    if (values.every(v => isEmpty(v))) {
      setStatus("idle");
    } else {
      handleOnChange();
    }
  };

  const onSearchFieldClear = (value: CategoryFieldValues) => {
    resetField(value);
    onClear();
  };

  const deactivateCategorySelectionStatus = () => {
    if (status === "selecting_category") {
      setStatus(previousStatus);
    }
  };

  const updateCategorySelectionStatus = () => {
    status !== "selecting_category" && !addressOnly
      ? setStatus("selecting_category")
      : setStatus(previousStatus);
  };

  return (
    <div ref={wrapperRef}>
      <div onFocus={() => setFocused(true)}>
        <Wrapper position={multipleFieldSearch ? "first" : undefined}>
          <CategoryWrapper
            clickable={!addressOnly}
            onClick={updateCategorySelectionStatus}
            onKeyDown={e => {
              if (e.key === "Enter") {
                updateCategorySelectionStatus();
              }
            }}
            tabIndex={addressOnly ? -1 : 0}
            data-testid="category-wrapper"
          >
            <IconWrapper
              iconPosition={"left"}
              icon="magnifyingGlass"
              clickable={!addressOnly}
            >
              <Icon icon={Icons.MAGNIFYING_GLASS} color={ICON_COLORS.GREY_2} />
            </IconWrapper>
            <div data-testid="selected-category">{selectedCategory.label}</div>
            {!addressOnly && (
              <IconWrapper
                iconPosition={"right"}
                icon="caretDown"
                expanded={status === "selecting_category"}
                clickable
              >
                <Icon icon={Icons.CARET_DOWN} color={ICON_COLORS.GREY_2} />
              </IconWrapper>
            )}
          </CategoryWrapper>
          <InputWrapper>
            {!multipleFieldSearch && (
              <SearchField
                listedField={selectedCategory.fields![0]!}
                control={control}
                handleOnChange={handleOnChange}
                deactivateCategorySelectionStatus={
                  deactivateCategorySelectionStatus
                }
                onSearchFieldClear={onSearchFieldClear}
              />
            )}
          </InputWrapper>
        </Wrapper>
        {multipleFieldSearch && (
          <>
            {selectedCategory.fields!.map((field: Field, index: number) => (
              <Wrapper
                position={
                  selectedCategory.fields!.length - 1 === index
                    ? "last"
                    : "middle"
                }
                key={field.value}
              >
                <Label tabIndex={0}>{field.label}</Label>
                <InputWrapper>
                  <SearchField
                    listedField={field}
                    control={control}
                    handleOnChange={handleOnChange}
                    deactivateCategorySelectionStatus={
                      deactivateCategorySelectionStatus
                    }
                    onSearchFieldClear={onSearchFieldClear}
                  />
                </InputWrapper>
              </Wrapper>
            ))}
          </>
        )}
      </div>
      {status === "selecting_category" && focused && (
        <CategoryDropdown
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onClear={onClear}
          reset={reset}
        />
      )}
      {(status === "loading" || status === "search_complete") && focused && (
        <ResultsDropdown
          results={data?.getSearchResults || []}
          onClear={onClear}
          handleResultClick={handleResultClick}
          reset={reset}
          status={status}
          resetSearchCategory={() => setSelectedCategory(defaultCategory)}
        />
      )}
    </div>
  );
};
