import React, { Dispatch, useContext, useEffect, useMemo, useRef } from "react";
import { flatten, isEqual, isUndefined } from "lodash";
import { FilterDescription } from "common/utils/queryBuilder";
import * as Dropdown from "@radix-ui/react-dropdown-menu";

import { Button } from "../../../Button";
import {
  buildFilterDescriptionFromFilterStateObject,
  getAllFilterableAttributes,
} from "./utils";
import { useOutsideAlerter } from "../../../../../utils/outsideAlerter";
import {
  FilterAction,
  FilterReducerState,
  useFilterReducer,
} from "./useFilterReducer";
import { Attribute, isAttributeNode } from "../../types";
import FilterRows from "./FilterRows";
import { FilterInput } from "./Inputs";
import { AttributeRow, SectionTitleNavigation } from "../utils";
import { styled } from "../../../../../stitches.config";

import {
  DropdownItemStyles,
  DropdownContentStyles,
  SectionTitleLabel,
  DropdownItemList,
} from "../__styles__/TableSettings";
import { FilterCountWrapper } from "./__styles__/Filter";
import Divider from "../../../Divider";
import { TableContext } from "../../TableContext";

const AppliedFiltersState = ({
  filterCount,
  filterState,
  dispatch,
}: {
  filterCount: number;
  filterState: FilterReducerState;
  dispatch: Dispatch<FilterAction>;
}) => {
  return (
    <>
      {filterCount === 0 ? (
        <SectionTitleLabel>No filter applied</SectionTitleLabel>
      ) : (
        <FilterRows filterState={filterState} dispatch={dispatch} />
      )}
      <Divider extension={8} />
      <DropdownItem
        onSelect={event => {
          event.preventDefault();
          dispatch({ type: "addingFilter" });
        }}
      >
        <AttributeRow attribute={{ label: "Add filter", icon: "plus" }} />
      </DropdownItem>
    </>
  );
};

const AddFilterState = ({
  attributeHistory,
  currentAttribute,
  dispatch,
}: {
  attributeHistory: Array<Attribute>;
  currentAttribute: Attribute;
  dispatch: Dispatch<FilterAction>;
}) => {
  const parentAttributeLabel =
    attributeHistory[attributeHistory.length - 1]?.label;
  const hasConditionalParent = !isUndefined(currentAttribute.conditional);

  let menuTitle = parentAttributeLabel ?? "Filters";

  if (hasConditionalParent) {
    menuTitle = "Conditionals";
  }

  return (
    <>
      <Dropdown.Item
        onSelect={event => {
          event.preventDefault();
          dispatch({ type: "goBack" });
        }}
      >
        <SectionTitleNavigation label={menuTitle} />
      </Dropdown.Item>
      <Divider extension={8} />
      {isAttributeNode(currentAttribute) ? (
        <>
          <SectionTitleLabel>Attributes</SectionTitleLabel>
          <DropdownItemList>
            {currentAttribute.attributes
              .filter(attribute => !attribute.omitFromFilters)
              .map((attribute, index) => (
                <DropdownItem
                  key={index}
                  onSelect={event => {
                    event.preventDefault();
                    dispatch({
                      type: "setAttribute",
                      data: {
                        newAttribute: attribute,
                        lastAttribute: currentAttribute,
                      },
                    });
                  }}
                >
                  <AttributeRow
                    attribute={attribute}
                    rightIcon={{ icon: "chevron-right" }}
                  />
                </DropdownItem>
              ))}
          </DropdownItemList>
        </>
      ) : (
        <FilterInput attribute={currentAttribute} dispatch={dispatch} />
      )}
    </>
  );
};

export const Filter = ({
  config,
  onFilterChange,
  addingNewView,
}: {
  config: Array<Attribute>;
  onFilterChange: (filters: Array<FilterDescription>) => void;
  addingNewView?: boolean;
}) => {
  const { currentQuery } = useContext(TableContext);

  const filterConfig = useMemo(
    () => getAllFilterableAttributes(config),
    [config]
  );

  const wrapperRef = useRef(null as null | HTMLDivElement);

  const [filterState, dispatch] = useFilterReducer({
    queryDescription: currentQuery!,
    initialAttributes: filterConfig,
  });

  useEffect(() => {
    if (
      currentQuery &&
      !isEqual(filterState.originalQueryDescription, currentQuery)
    ) {
      dispatch({
        type: "buildNewFilterFromQuery",
        data: {
          queryDescription: currentQuery,
        },
      });
    }
  }, [currentQuery]);

  const { currentAttribute, attributeHistory, menuOpen, addingFilter } =
    filterState;

  const filterCount = flatten(Object.values(filterState.filterValues)).length;

  const toggleMenu = () => {
    if (menuOpen) {
      dispatch({ type: "closeMenu" });
    } else {
      dispatch({ type: "openMenu" });
    }
  };

  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => {
      dispatch({ type: "closeMenu" });
    },
  });

  useEffect(() => {
    const filterDescriptions: Array<FilterDescription> = [];

    for (const [key, filters] of Object.entries(filterState.filterValues)) {
      for (const filter of filters) {
        filterDescriptions.push(
          buildFilterDescriptionFromFilterStateObject({
            field: key,
            filter,
          })
        );
      }
    }

    onFilterChange(filterDescriptions);
  }, [JSON.stringify(filterState.filterValues)]);

  useEffect(() => {
    if (addingNewView) {
      dispatch({ type: "resetFilters" });
    }
  }, [addingNewView]);

  return (
    <div style={{ userSelect: "none" }}>
      <Button
        onClick={toggleMenu}
        leftIconName="filter"
        styleVariant="secondary"
        size="small"
      >
        Filter
        {filterCount > 0 && (
          <FilterCountWrapper data-testid="filter-count">
            {filterCount}
          </FilterCountWrapper>
        )}
      </Button>
      <Dropdown.Root open={menuOpen} onOpenChange={toggleMenu}>
        <Dropdown.Trigger asChild>
          <div />
        </Dropdown.Trigger>
        <DropdownContent align="start" sideOffset={4}>
          {addingFilter ? (
            <AddFilterState
              currentAttribute={currentAttribute}
              attributeHistory={attributeHistory}
              dispatch={dispatch}
            />
          ) : (
            <AppliedFiltersState
              filterCount={filterCount}
              dispatch={dispatch}
              filterState={filterState}
            />
          )}
        </DropdownContent>
      </Dropdown.Root>
    </div>
  );
};

const DropdownContent = styled(Dropdown.Content, {
  ...DropdownContentStyles,
});

const DropdownItem = styled(Dropdown.Item, {
  ...DropdownItemStyles,
});
