import React, { ChangeEvent, useState } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../../Common/Modal";
import { Text as TextInput } from "../../../Inputs";
import { Button } from "../../../Common/Button";

import { Property } from "./types";
import { useUpdatePropertyFirmMutation } from "../../../../generated/graphql";
import { formatFeet, truncateStringDecimal } from "common/utils/strings";

import {
  ButtonSection,
  Container,
  FormSection,
  HeaderSection,
  ContentSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Cell, Header, Table } from "./__styles__/EditModal";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import {
  OptionType,
  findFloodZoneStartingOptionIndex,
  generateFloodZoneOptions,
} from "common-client/utils/firms";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { isAOZone } from "common/services/firmHelpers";
import { FIRM_UPDATE_REFECTH_QUERIES } from "./FIRMEditModal";

export interface FloodzoneEditFormProps {
  property: NonNullable<Property>;
  firm?: FIRMWithWarnings;
  openApproximateBfeTool?: () => void;
  onCancel: () => void;
  onSave: () => void;
  refetchQueries?: string[];
}

export const useFloodzoneEditModal = ({
  onSave,
  onCancel,
  ...rest
}: FloodzoneEditFormProps) => {
  const [showFloodzoneEditModal, hideFloodzoneEditModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideFloodzoneEditModal();
          onCancel();
        }}
      >
        <FloodzoneEditForm
          onCancel={() => {
            hideFloodzoneEditModal();
            onCancel();
          }}
          onSave={() => {
            hideFloodzoneEditModal();
            onSave();
          }}
          {...rest}
        />
      </Modal>
    ),
    [rest, onSave]
  );

  return [showFloodzoneEditModal, hideFloodzoneEditModal] as const;
};

const FloodzoneEditForm = ({
  property,
  firm,
  onCancel,
  onSave,
  openApproximateBfeTool,
  refetchQueries = FIRM_UPDATE_REFECTH_QUERIES,
}: FloodzoneEditFormProps) => {
  if (!firm) return null;

  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const propertyId = property.id;

  const [options, setOptions] = useState(() => generateFloodZoneOptions(firm));

  const [currentOptionIndex, setCurrentOptionIndex] = useState(() =>
    findFloodZoneStartingOptionIndex({ firm, options })
  );

  const currentOption = options[currentOptionIndex];
  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const optionIndex = parseInt(event.target.value);
    setCurrentOptionIndex(optionIndex);
  };

  const generateCell = (
    option: OptionType,
    mode: "stringDepth" | "stringStaticBFE"
  ) => {
    const isFillerCell =
      (mode === "stringDepth" && !isAOZone(option.floodzone)) ||
      (mode === "stringStaticBFE" && isAOZone(option.floodzone));

    const hasTextInput = option.isCustom && !isFillerCell;
    if (hasTextInput) {
      return (
        <Cell isCustom={true}>
          <TextInput
            name="custom"
            value={option[mode] ?? null}
            placeholder="Custom"
            onChange={value => {
              setOptions(prevOptions => {
                const targetOptionIndex = prevOptions.findIndex(
                  possibleTarget =>
                    possibleTarget.isCustom &&
                    possibleTarget.floodzone === option.floodzone
                );
                const updatedOptions = [...prevOptions];
                updatedOptions[targetOptionIndex]![mode] = value;
                return updatedOptions;
              });
            }}
            disabled={currentOption === option ? false : true}
            size="small"
            type="number"
            step="0.1"
          />
        </Cell>
      );
    }

    return (
      <Cell isCustom={false}>
        {option[mode] ? formatFeet(option[mode]) : "-"}
      </Cell>
    );
  };

  const [updateFIRM, { loading }] = useUpdatePropertyFirmMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: () => {
      onSave();
      addSuccessToast(`${firm.name} data successfully updated`);
    },
    onError: () => {
      addErrorToast(
        `There was an issue updating the ${firm.name} data. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const handleCancel = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onCancel();
  };

  const handleSave = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    // Don't double-submit
    if (loading || !currentOption) return;

    const formattedBFE = currentOption.isCustom
      ? truncateStringDecimal({
          value: currentOption.stringStaticBFE,
        })
      : currentOption.stringStaticBFE;

    const formattedBFD = currentOption.isCustom
      ? truncateStringDecimal({
          value: currentOption.stringDepth,
        })
      : currentOption.stringDepth;

    await updateFIRM({
      variables: {
        propertyId,
        firmId: firm.id,
        data: {
          floodzone: currentOption?.floodzone,
          stringStaticBFE: formattedBFE,
          stringDepth: formattedBFD,
          isApproximateBfe: false,
        },
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>{firm.name} Determination</h1>
        <h2>
          Select the correct flood zone & BFE for the structure on the{" "}
          {firm.name}.
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection style={{ lineHeight: "1.15" }}>
          <Table>
            <Header>
              <tr>
                <Cell isRadio></Cell>
                <Cell key={"floodzone"}>{"Flood zone"}</Cell>
                <Cell key={"bfe"}>{"Base Flood Elevation"}</Cell>
                <Cell key={"depth"}>{"Base Flood Depth"}</Cell>
              </tr>
            </Header>
            <tbody>
              {options.map((option: OptionType, index: number) => {
                return (
                  <tr key={index}>
                    <Cell isRadio>
                      <input
                        type="radio"
                        name="option"
                        value={index}
                        checked={currentOptionIndex === index}
                        onChange={handleOptionChange}
                        disabled={loading}
                      />
                    </Cell>
                    <Cell>{option.floodzone}</Cell>
                    {generateCell(option, "stringStaticBFE")}
                    {generateCell(option, "stringDepth")}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ContentSection>
        <ButtonSection>
          {openApproximateBfeTool && (
            <Button
              styleVariant="ghost"
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                openApproximateBfeTool();
              }}
              disabled={loading}
              size="medium"
            >
              Calculate approximate BFE
            </Button>
          )}
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={handleCancel}
              disabled={loading}
              size="medium"
            >
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              onClick={handleSave}
              disabled={
                (currentOption?.isCustom &&
                  !currentOption.stringStaticBFE &&
                  !currentOption.stringDepth) ||
                loading
              }
              size="medium"
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default FloodzoneEditForm;
