import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";

import { LayerContext } from ".";
import { colors } from "../../../stitches.config";
import { group, SAVED_VIEW_MIN_ZOOM } from "./savedViews";
import {
  CLUSTER_CIRCLE_RADIUS,
  CLUSTER_COUNT_DISPLAY,
} from "common/utils/maps/index";
import { textHaloColor } from "common-client/utils/mapLayers";

export const viewsClusterSourceId = "saved-views-cluster-source";
export const viewsClusterLayerId = "saved-views-cluster";

const CLUSTER_MIN_ZOOM = 10;

const SavedViewsClusterLayer = ({
  savedViewIds,
}: {
  savedViewIds: Array<string>;
}) => {
  const { visibleLayerIdsForGroup } = useContext(LayerContext);
  const isLayerVisible = visibleLayerIdsForGroup(group).length > 0;

  const viewTiles = [
    `${
      window.env.APPLICATION_URL
    }/api/tiles/views-cluster/{z}/{x}/{y}?savedViewIds=${savedViewIds.join(
      ","
    )}`,
  ];

  return (
    <Source
      id={viewsClusterSourceId}
      tiles={viewTiles}
      type="vector"
      maxzoom={SAVED_VIEW_MIN_ZOOM}
      minzoom={CLUSTER_MIN_ZOOM}
    >
      <Layer
        id={viewsClusterLayerId}
        type="circle"
        source-layer="src"
        layout={{
          visibility: isLayerVisible ? "visible" : "none",
        }}
        paint={{
          "circle-radius": CLUSTER_CIRCLE_RADIUS,
          "circle-opacity": 1,
          "circle-color": colors.featureGrey.value,
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 0.4,
          "circle-stroke-color": colors.featureGrey.value,
        }}
        maxzoom={SAVED_VIEW_MIN_ZOOM}
        minzoom={CLUSTER_MIN_ZOOM}
      />

      <Layer
        id={`${viewsClusterLayerId}-label`}
        type="symbol"
        source-layer="src"
        layout={{
          visibility: isLayerVisible ? "visible" : "none",
          "text-field": CLUSTER_COUNT_DISPLAY,
          "text-padding": 10,
          "text-size": 15,
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
          "text-allow-overlap": true,
        }}
        maxzoom={SAVED_VIEW_MIN_ZOOM}
        minzoom={CLUSTER_MIN_ZOOM}
        paint={{
          "text-color": colors.contentPrimaryDark.value,
          "text-halo-color": textHaloColor,
          "text-halo-width": 1,
        }}
      />
    </Source>
  );
};

export default SavedViewsClusterLayer;
