import React, { ReactNode, useContext } from "react";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

import { Box } from "../../Common/__styles__/Layout";
import Divider from "../../Common/Divider";
import {
  useGetPublicWebsiteSettingsQuery,
  useUpdatePublicWebsiteSettingsMutation,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";
import { RESOURCE_NAME } from "common/authorization";
import { PublicWebsiteSettingsForm } from "./PublicWebsiteSettingsForm";

import { Header, HeaderTitle, Footer } from "../__styles__/Content";

export const PublicWebsite = () => {
  const { authorized } = useContext(AuthContext);

  const { addSuccessToast, addErrorToast } = useStatusToasts();

  const { data, loading, error } = useGetPublicWebsiteSettingsQuery();

  const [updateAccountContactInformation] =
    useUpdatePublicWebsiteSettingsMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your public website settings were successfully updated."
        );
      },
      onError: () => {
        addErrorToast(
          "There was a problem updating your public website settings. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const contactInformation = data?.account?.contactInformation;
  const publicAccountPortal = data?.account?.publicPortal;

  const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
      <>
        {!canUpdateAccountInformation && <InsufficientPermissionsBanner />}
        <Box>
          <Header>
            <HeaderTitle>Public Website settings</HeaderTitle>
          </Header>
          <Divider />
          {children}
          <Footer />
        </Box>
      </>
    );
  };

  if (loading) {
    return <Wrapper>loading...</Wrapper>;
  }

  if (error || !contactInformation || !publicAccountPortal) {
    return <div />;
  }

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });

  return (
    <Wrapper>
      <PublicWebsiteSettingsForm
        canUpdateAccountInformation={canUpdateAccountInformation}
        settings={{
          ...contactInformation,
          ...publicAccountPortal,
        }}
        onUpdate={updateAccountContactInformation}
      />
    </Wrapper>
  );
};

export default PublicWebsite;
