import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import {
  SubmissionsBuilderFormDataStructure,
  InputFieldPath,
} from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { FieldSettingConfig } from "./shared";

const TextWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <FieldSettingConfig fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <div>
        <Label text={"Placeholder text"} htmlFor={`${fieldPath}.placeholder`} />
        <Text {...register(`${fieldPath}.placeholder`)} />
      </div>
      <div>
        <Label
          text={"Unique identifier"}
          htmlFor={`${fieldPath}.uniqueIdentifier`}
        />
        <Text {...register(`${fieldPath}.uniqueIdentifier`)} />
      </div>
    </WidgetSettingsContainer>
  );
};

TextWidgetSettings.displayName = "TextWidgetSettings";

export default TextWidgetSettings;
