import React from "react";
import { Select, Text, Textarea } from "../../../../../Inputs/react-hook-form";
import { Label } from "../../../../../Inputs";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../types";
import { categoryOptions, intakeSourceOptions } from "../constants";
import { SubmissionIntakeSource } from "../../../../../../generated/graphql";

const FormSettingsTab = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const intakeSource = watch("intakeSource");

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div>
        <Label text={"All fields are required"} required />
      </div>
      <Text
        {...register("name", { required: "Name is required" })}
        label="Form title"
        error={errors.name?.message}
      />
      <div>
        <Label text="Record type" htmlFor="category" />
        <Select
          control={control}
          name="category"
          options={categoryOptions}
          size="medium"
          required={true}
          rules={{ required: "Record type is required" }}
        />
      </div>
      <div>
        <Label text="Intake source" htmlFor="intakeSource" />
        <Select
          control={control}
          name="intakeSource"
          options={intakeSourceOptions}
          size="medium"
          required={true}
          rules={{ required: "Intake source is required" }}
          error={errors.intakeSource?.message}
          onChange={value => {
            if (value === SubmissionIntakeSource.INTERNAL) {
              setValue("preamble", null);
              setValue("postamble", null);
              setValue("notificationEmails", null);
            } else {
              setValue("successMessage", null);
              setValue("notificationEmails", "");
            }
          }}
        />
      </div>

      <div>
        <Label text={"Form description"} htmlFor="description" />
        <Textarea
          {...register("description")}
          error={errors.description?.message}
        />
      </div>
      {intakeSource === SubmissionIntakeSource.INTERNAL && (
        <div>
          <Label text={"Success message"} htmlFor="successMessage" />
          <Text
            {...register("successMessage", {
              required: "Success message is required",
            })}
            error={errors.successMessage?.message}
          />
        </div>
      )}
      {intakeSource === SubmissionIntakeSource.EXTERNAL && (
        <>
          <div>
            <Label text="Success page title" htmlFor="preamble" />
            <Text
              {...register("preamble", {
                required: "Preamble is required",
              })}
              error={errors.preamble?.message}
            />
          </div>
          <div>
            <Label text="Success page message" htmlFor="postamble" />
            <Text
              {...register("postamble", {
                required: "Postamble is required",
              })}
              error={errors.postamble?.message}
            />
          </div>
          <div>
            <Label
              text="Notification emails"
              htmlFor="notificationEmails"
              tooltip="Please enter each email on its own line"
            />
            <Textarea
              {...register("notificationEmails")}
              error={errors.notificationEmails?.message}
              data-testid="notification-emails"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormSettingsTab;
