import React, { FC, useContext, useEffect, useState } from "react";
import {
  BuilderSidebarHeader,
  BuilderSidebarWrapper,
} from "../__styles__/SubmissionsBuilder";
import { useFormContext } from "react-hook-form";
import { Button } from "../../../../../Common/Button";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { SubmissionsBuilderFormDataStructure } from "../types";
import TextWidgetSettings from "./FieldSettings/TextWidgetSettings";
import NumberWidgetSettings from "./FieldSettings/NumberFieldSettings";
import DateWidgetSettings from "./FieldSettings/DateWidgetSettings";
import TextareaWidgetSettings from "./FieldSettings/TextareaWidgetSettings";
import CheckboxWidgetSettings from "./FieldSettings/CheckboxWidgetSettings";
import ObjectFieldTemplateSettings from "./FieldSettings/ObjectFieldTemplateSettings";
import PropertyMarketFieldSettings from "./FieldSettings/PropertyMarketValueFieldSettings";
import DamageDegreeWidgetSettings from "./FieldSettings/DamageDegreeWidgetSettings";
import DocumentUploadFieldSettings from "./FieldSettings/DocumentUploadFieldSettings";
import DropdownWidgetSettings from "./FieldSettings/DropdownWidgetSettings";
import TagsWidgetSettings from "./FieldSettings/TagsWidgetSettings";
import NumberWithUnitsFieldSettings from "./FieldSettings/NumberWithUnitsFieldSettings";
import {
  inputTypeToFieldsListLabelMap,
  MODULES,
  SubmissionsBuilderFieldType,
} from "../constants";
import { SubmissionsBuilderContextInstance } from "../context";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { getAllInputNames } from "../utils";
import { Body } from "../../../../../Common/Typography";

const fieldKeyToSettingsFormMap: Record<
  SubmissionsBuilderFieldType,
  FC<{
    isModuleInput: boolean;
    name: string;
    inputType: SubmissionsBuilderFieldType;
    fieldPath: `inputs.${number}`;
  }>
> = {
  text: TextWidgetSettings,
  textarea: TextareaWidgetSettings,
  number: NumberWidgetSettings,
  NumberWithUnits: NumberWithUnitsFieldSettings,
  date: DateWidgetSettings,
  checkbox: CheckboxWidgetSettings,
  group: ObjectFieldTemplateSettings,
  PropertyMarketValue: PropertyMarketFieldSettings,
  DamageDegree: DamageDegreeWidgetSettings,
  DocumentUploader: DocumentUploadFieldSettings,
  select: DropdownWidgetSettings,
  Tags: TagsWidgetSettings,
};

const FieldSettingsView = () => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();
  const { selectedField, toggleFormSettingsMode } = useContext(
    SubmissionsBuilderContextInstance
  );

  const [associatedModules, setAssociatedModules] = useState<
    Array<SUBMISSION_TYPE_MODULE>
  >([]);

  const modules = watch("modules");
  useEffect(() => {
    setAssociatedModules(
      MODULES.filter(module => modules.includes(module.type))
        .filter(module => {
          const inputNames = getAllInputNames([...module.inputs]);
          return inputNames.some(
            inputName => inputName === selectedField?.name
          );
        })
        .map(module => module.type)
    );
  }, [modules, selectedField?.name]);

  if (!selectedField) {
    return null;
  }

  const FieldSettingsForm = fieldKeyToSettingsFormMap[selectedField.inputType];
  const title =
    selectedField.inputType === "DamageDegree"
      ? "Damage degree"
      : inputTypeToFieldsListLabelMap[selectedField.inputType];

  // when a field is selected, the side column changes its content to show the field settings
  // when you're in field mode, you can navigate BACK to selected fields
  return (
    <BuilderSidebarWrapper role="complementary">
      <BuilderSidebarHeader role="none">
        <Button
          aria-label="Back to form fields"
          style={{ padding: 0 }}
          styleVariant="ghost"
          onClick={toggleFormSettingsMode}
        >
          <Icon color="contentSecondary" iconName="chevron-left" size={16} />
        </Button>
        <Body size="large" type="emphasis">
          {`${title} field properties`}
        </Body>
      </BuilderSidebarHeader>
      {/* adding `key` prop here to force re-mounting of component on selectedField change */}
      <FieldSettingsForm
        key={selectedField.name}
        {...selectedField}
        isModuleInput={!!associatedModules.length}
      />
    </BuilderSidebarWrapper>
  );
};

export default FieldSettingsView;
