import { gql } from "@apollo/client";
import React, { useState } from "react";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

import { track } from "../../../utils/tracking";
import { Textarea } from "../../Inputs";
import { useCreateCommentMutation } from "../../../generated/graphql";
import { StyledButton } from "./__styles__/CommentForm";

export interface CommentFormProps {
  propertyId: string;
  onUpdate: () => void;
}

export const CREATE_COMMENT = gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      id
      content
    }
  }
`;

const CommentForm = ({ propertyId, onUpdate }: CommentFormProps) => {
  const { addErrorToast } = useStatusToasts();
  const [content, setContent] = useState("");

  const [createComment, { loading }] = useCreateCommentMutation({
    onCompleted: response => {
      if (response.createComment.id) {
        track("Comment Created");
      }
      setContent("");
      onUpdate();
    },
    onError: () => {
      addErrorToast(
        `There was an error creating this comment. Please try again. If the problem persists, please email us at support@withforerunner.com`
      );
    },
  });

  const submitComment = async () => {
    if (loading) return;
    const cleanContent = content.trim();
    await createComment({
      variables: { data: { content: cleanContent, propertyId } },
    });
  };

  return (
    <form onSubmit={submitComment}>
      <section style={{ position: "relative" }}>
        <Textarea
          name="comment"
          value={content.trimStart()}
          onChange={setContent}
          placeholder="Leave a comment..."
          styleOverride={{
            paddingTop: "10px",
            paddingBottom: "60px",
            resize: "none",
          }}
          size="small"
          minRows={2}
        />
        <StyledButton
          disabled={loading || !content.trim()}
          onClick={submitComment}
          styleVariant="primary"
          size="small"
          type="button"
        >
          Comment
        </StyledButton>
      </section>
    </form>
  );
};

export default CommentForm;
