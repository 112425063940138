import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DocumentNode } from "graphql";

import { Box } from "../../Common/__styles__/Layout";
import Divider from "../../Common/Divider";
import { ExpandableList } from "../../Common/ExpandableList";
import { Markdown } from "../../Inputs/Markdown";
import {
  DisclaimerName,
  useUpdateDisclaimerOverrideMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";
import { CURRENT_ADMIN } from "../../Authorization/__queries__";
import {
  defaultGuestCertificatesDisclaimer,
  defaultGuestDocumentsDisclaimer,
  DEFAULT_POINT_GEOMETRY_DISCLAIMER,
  DEFAULT_GUEST_SISD_DISCLAIMER,
  DEFAULT_PARCEL_GEOMETRY_DISCLAIMER,
  DEFAULT_USER_SISD_DISCLAIMER,
  getDisclaimerOverride,
  defaultBuildingInformationDisclaimer,
  defaultFirmDigitizationDisclaimer,
  defaultBFEOverriddenDisclaimer,
  DEFAULT_BASE_FLOOD_DEPTH_DISCLAIMER,
} from "common-client/utils/disclaimerOverrides";
import { Button } from "../../Common/Button";

import { DisclaimerForm, ButtonWrapper } from "./__styles__/Disclaimers";
import { Header, HeaderTitle, Footer, Section } from "../__styles__/Content";

const publicWebsiteDisclaimers = [
  {
    title: "Elevation Certificate page header disclaimer",
    disclaimerName: DisclaimerName.CERTIFICATE_PAGE,
    defaultDisclaimer: defaultGuestCertificatesDisclaimer({
      accountName: "ACCOUNT_NAME",
    }),
  },
  {
    title: "Public Property Profile file tab disclaimer",
    disclaimerName: DisclaimerName.DOCUMENTS,
    defaultDisclaimer: defaultGuestDocumentsDisclaimer({
      accountName: "ACCOUNT_NAME",
      accountId: "",
      propertyId: "PROPERTY_ID",
    }),
  },
  {
    title:
      "Public Property Profile flood information coordinates geometry disclaimer",
    disclaimerName: DisclaimerName.POINT_GEOMETRY,
    defaultDisclaimer: DEFAULT_POINT_GEOMETRY_DISCLAIMER,
  },
  {
    title:
      "Public Property Profile flood information parcel geometry disclaimer",
    disclaimerName: DisclaimerName.PARCEL_GEOMETRY,
    defaultDisclaimer: DEFAULT_PARCEL_GEOMETRY_DISCLAIMER,
  },
  {
    title: "Public Property Profile custom digitization/dataset disclaimer",
    disclaimerName: DisclaimerName.FIRM_DIGITIZATION,
    previewContent: defaultFirmDigitizationDisclaimer({
      digitizedFIRMs: [{ name: "FIRM_NAME" }],
    }),
  },
  {
    title: "Public Property Profile building information disclaimer",
    disclaimerName: DisclaimerName.BUILDING_INFO,
    defaultDisclaimer: defaultBuildingInformationDisclaimer({
      certificateIssuedAt: "ISSUED_AT",
    }),
  },
  {
    title: "Public Property Profile SI/SD tab disclaimer",
    disclaimerName: DisclaimerName.SISD,
    defaultDisclaimer: DEFAULT_GUEST_SISD_DISCLAIMER,
  },
  {
    title: "Public Property Profile non-FIRM BFE and DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE_AND_DFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: true,
      },
    })!,
  },
  {
    title: "Public Property Profile non-FIRM BFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: false,
      },
    })!,
  },
  {
    title: "Public Property Profile non-FIRM DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_DFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: false,
        dfe: true,
      },
    })!,
  },
  {
    title: "Public Property Profile non-FIRM BFD disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFD,
    previewContent: DEFAULT_BASE_FLOOD_DEPTH_DISCLAIMER,
  },
];

const internalDisclaimers = [
  {
    title:
      "Property Information Panel flood information coordinates geometry disclaimer",
    disclaimerName: DisclaimerName.POINT_GEOMETRY,
    defaultDisclaimer: DEFAULT_POINT_GEOMETRY_DISCLAIMER,
  },
  {
    title:
      "Property Information Panel flood information parcel geometry disclaimer",
    disclaimerName: DisclaimerName.PARCEL_GEOMETRY,
    defaultDisclaimer: DEFAULT_PARCEL_GEOMETRY_DISCLAIMER,
  },

  {
    title: "Property Information Panel custom digitization/dataset disclaimer",
    disclaimerName: DisclaimerName.FIRM_DIGITIZATION,
    previewContent: defaultFirmDigitizationDisclaimer({
      digitizedFIRMs: [{ name: "FIRM_NAME" }],
    }),
  },
  {
    title: "Property Information Panel building information disclaimer",
    disclaimerName: DisclaimerName.BUILDING_INFO,
    defaultDisclaimer: defaultBuildingInformationDisclaimer({
      certificateIssuedAt: "ISSUED_AT",
    }),
  },
  {
    title: "Property Information Panel SI/SD tab disclaimer",
    disclaimerName: DisclaimerName.SISD,
    defaultDisclaimer: DEFAULT_USER_SISD_DISCLAIMER,
  },
  {
    title: "Property Information Panel non-FIRM BFE and DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE_AND_DFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: true,
      },
    })!,
  },
  {
    title: "Property Information Panel non-FIRM BFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: true,
        dfe: false,
      },
    })!,
  },
  {
    title: "Property Information Panel non-FIRM DFE disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_DFE,
    previewContent: defaultBFEOverriddenDisclaimer({
      overrideType: {
        bfe: false,
        dfe: true,
      },
    })!,
  },
  {
    title: "Property Information Panel non-FIRM BFD disclaimer",
    disclaimerName: DisclaimerName.OVERRIDDEN_BFD,
    previewContent: DEFAULT_BASE_FLOOD_DEPTH_DISCLAIMER,
  },
];

export type DisclaimerProps = {
  disclaimerName: DisclaimerName;
  defaultDisclaimer?: string;
  previewContent?: string;
  isPublic: boolean;
  refetchQueries?: Array<{ query: DocumentNode }>;
};

export const Disclaimer = ({
  disclaimerName,
  previewContent,
  defaultDisclaimer = "",
  isPublic,
  //if we expose this to users/managers we will need to add {query: CURRENT_USER} to the refetch queries
  refetchQueries = [{ query: CURRENT_ADMIN }],
}: DisclaimerProps) => {
  const { account } = useContext(AuthContext);
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [updateDisclaimerOverride] = useUpdateDisclaimerOverrideMutation({
    refetchQueries,
    onCompleted: () => {
      addSuccessToast("Your disclaimer was successfully updated.");
    },
    onError: () => {
      addErrorToast(
        "There was a problem updating your disclaimer. Please try again or contact support@withforerunner.com"
      );
    },
  });

  const disclaimerKey = `${disclaimerName}-${isPublic ? "public" : "internal"}`;

  const lastSavedDisclaimer = getDisclaimerOverride({
    disclaimerName,
    isPublic,
    disclaimerOverrides: account?.disclaimerOverrides,
  });

  const useFormProps = useForm<{ [key: string]: string }>({
    defaultValues: {
      [disclaimerKey]: lastSavedDisclaimer ?? "",
    },
  });

  const currentFormValue = useFormProps.watch(disclaimerKey);

  //only allow update if disclaimer has been changed
  const canUpdate =
    (lastSavedDisclaimer !== null || currentFormValue !== "") &&
    lastSavedDisclaimer !== currentFormValue;

  return (
    <DisclaimerForm>
      <FormProvider {...useFormProps}>
        <Markdown
          name={disclaimerKey}
          control={useFormProps.control}
          previewContent={previewContent}
          placeholderContent={defaultDisclaimer}
        />
        {!previewContent && (
          <ButtonWrapper>
            <Button
              styleVariant="primary"
              size="large"
              onClick={useFormProps.handleSubmit(async data => {
                await updateDisclaimerOverride({
                  variables: {
                    data: {
                      disclaimerName,
                      content: data[disclaimerKey] ?? "",
                      isPublic,
                    },
                  },
                });
              })}
              disabled={!canUpdate}
            >
              Update disclaimer
            </Button>
          </ButtonWrapper>
        )}
      </FormProvider>
    </DisclaimerForm>
  );
};

export const Disclaimers = () => {
  const { authorized, account } = useContext(AuthContext);

  const canUpdateAccountDisclaimerOverrides = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "updateDisclaimerOverrides",
  });

  return (
    <Box>
      <Header>
        <HeaderTitle>Disclaimer settings</HeaderTitle>
      </Header>
      <Divider />
      <Section>
        {!canUpdateAccountDisclaimerOverrides ? (
          <InsufficientPermissionsBanner />
        ) : (
          <>
            {account?.publicPortal.enabled && (
              <ExpandableList
                title="Public website disclaimers"
                list={publicWebsiteDisclaimers.map(disclaimer => ({
                  title: disclaimer.title,
                  expandableContent: (
                    <Disclaimer
                      disclaimerName={disclaimer.disclaimerName}
                      isPublic={true}
                      previewContent={disclaimer.previewContent}
                      defaultDisclaimer={disclaimer.defaultDisclaimer}
                    />
                  ),
                }))}
              />
            )}
            <ExpandableList
              title="Internal disclaimers"
              list={internalDisclaimers.map(disclaimer => ({
                title: disclaimer.title,
                expandableContent: (
                  <Disclaimer
                    disclaimerName={disclaimer.disclaimerName}
                    defaultDisclaimer={disclaimer.defaultDisclaimer}
                    isPublic={false}
                    previewContent={disclaimer.previewContent}
                  />
                ),
              }))}
            />
          </>
        )}
      </Section>
      <Footer />
    </Box>
  );
};

export default Disclaimers;
